<!-- 校长报告 -->
<template>
    <div class="report">
        <template v-if="gradeList.length>0">
            <div class="content flex-column-center" id='pdfDom' v-if="currentType=='grade'">
                <div class="all flex-column-center" id="gradeAll">
                    <div class="page_one flex-column-center">
                        <div class="school">{{schoolName}}</div>
                        <div class="title">学生识写能力</div>
                        <!-- <el-button type="primary" @click="exportpdf" icon="el-icon-download">下载学校报告</el-button> -->
                        <div class="name">
                            <span>后</span>
                            <span>测</span>
                            <span>报</span>
                            <span>告</span>
                        </div>
                        <div class="serve">首都师范大学“双优云桥”教育服务中心</div>
                    </div>
                    <div class="page_two flex-column">
                        <div class="title">整体报告</div>
                        <div class="item_title">一、总述</div>
                        <p>为在本校精准、高效的开展关于规范汉字书写的常规教学活动，由首都师范大学首师优字团队，对我校在校学生集中进行了规范汉字识写能力后期测评。
                            经过前期试卷收集及入库，本次后测活动共有{{allInfo.stuNum}}个学生参与，
                            共回收到有效后测试卷{{allData.result}}页（每份试卷两页）。经分析整体情况如下：</p>
                        <p>1.在后测中，针对握笔姿势问题，我们发现有{{holdInfo.failNum}}名学生使用了错误的握笔姿势（如拇指内扣、拇指藏在食指后边、勾手腕），占全校的{{holdInfo.faliRatio}}%。针对常见的学生握笔姿势问题，我们在平台上提供了专家录制的握笔教学视频，
                            教师可以在上课之前用几分钟时间给学生播放，并进行纠正，逐渐养成良好的书写习惯。</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                        </div>
                        <p>2.
                            在后测中，针对坐姿问题，我们发现有{{sitInfo.failNum}}名学生出现坐姿错误，占全校的{{sitInfo.faliRatio}}%。学生常见错误坐姿的问题主要是：学生趴在桌子上写字、身体倾向一侧，或者靠着桌子侧，这样可能会引起驼背、近视、脊柱侧弯等问题，
                            需要予以指导和纠正。正确的坐姿是，身体坐直，把书本放平，做到“三个一”：眼离书本一尺，胸离桌边一拳，手离笔尖一寸远。</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/sitdefault.png">
                        </div>
                        <p>3.关于学生书写基础，针对后测样本数据综合分析四个书写基础维度得分情况如下：
                            作品完整度平均分为{{allInfo.all_wzd}}%，{{allInfo.all_wzd>82.42?'高':'低'}}于全国常模82.42%；
                            例字相似性平均分为{{allInfo.all_xsx}}%，{{allInfo.all_xsx>79.82?'高':'低'}}于全国常模79.82%；
                            占格一致性平均分为{{allInfo.all_yzx}}%，{{allInfo.all_yzx>74.45?'高':'低'}}于全国常模74.45%；
                            布局整齐度均分为{{allInfo.all_zqd}}%，{{allInfo.all_zqd>73.40?'高':'低'}}于全国常模73.40%。
                            整体来看，学生具备一定的书写基础，相较于全国常模，建议参与测评的学生在例字相似性和布局整齐度两方面需再度提升。如图：
                        </p>
                        <table border="1">
                            <tr>
                                <td>书写维度</td>
                                <td>考核要求</td>
                                <td>后测平均水平</td>
                                <td>全国常模</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>内容完整、用字正确，标点符号使用精准</td>
                                <td>{{allInfo.all_wzd}}%</td>
                                <td>82.42%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>笔法正确，字法规范，与规范例字书写相似</td>
                                <td>{{allInfo.all_xsx}}%</td>
                                <td>79.82%</td>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>书写自然流畅，大小占格合理</td>
                                <td>{{allInfo.all_yzx}}%</td>
                                <td>74.45%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>排布协调，横成行、纵成列，占格居中</td>
                                <td>{{allInfo.all_zqd}}%</td>
                                <td>73.40%</td>
                            </tr>
                        </table>
                        <div class="myChart" id="myChart15"></div>
                        <p>
                            ●
                            作品完整度平均分为{{allInfo.all_wzd}}%，{{allInfo.all_wzd>82.42?'高':'低'}}于全国常模82.42%，{{allComments.wzd[comparisonWd(allInfo.all_wzd)]}}
                        </p>
                        <p>
                            ●
                            例字相似性平均分为{{allInfo.all_xsx}}%，{{allInfo.all_xsx>79.82?'高':'低'}}于全国常模79.82%，{{allComments.xsx[comparisonWd(allInfo.all_xsx)]}}
                        </p>
                        <p>
                            ●
                            占格一致性平均分为{{allInfo.all_yzx}}%，{{allInfo.all_yzx>74.45?'高':'低'}}于全国常模74.45%，{{allComments.yzx[comparisonWd(allInfo.all_yzx)]}}
                        </p>
                        <p>
                            ●
                            布局整齐度均分为{{allInfo.all_zqd}}%，{{allInfo.all_zqd>73.40?'高':'低'}}于全国常模73.40%，{{allComments.zqd[comparisonWd(allInfo.all_zqd)]}}
                        </p>
                        <p>
                            4.在后测中，关于学生的书写辨析，从例字相似性维度来看，本校包围结构平均分为{{allInfo.all_baowei}}%，
                            上下结构平均分为{{allInfo.all_shangxia}}%，左右结构平均分为{{allInfo.all_zuoyou}}%，独体结构平均分为{{allInfo.all_duti}}%，
                            针对不同结构的生字书写技巧，首师优字平台提供了详细的书写指导视频与窍门讲解，建议各年级可以根据各年级的数据情况，着重选用不同结构的生字课件或视频进行教学及书写练习。从不同结构进行分析如下：
                        </p>
                        <div class="myChart" id="myChart16"></div>
                        <p>
                            ● 包围结构字形，在书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。包围结构的字，
                            {{constructionInfo.bwMaxName}}年级平均得分最高，为{{constructionInfo.bwMax}}%，{{constructionInfo.bwMinName}}平均得分最低，为{{constructionInfo.bwMin}}%。
                        </p>
                        <p>
                            ●
                            左右结构在常用汉字中的占比高达63.7%，此次后测中，学生对属于左右结构的单字，包括其部件与部件之间的位置、大小及高低排布等关系处理上，技巧运用更为熟练。左右结构的生字，
                            {{constructionInfo.zyMaxName}}年级平均得分最高，为{{constructionInfo.zyMax}}%，{{constructionInfo.zyMinName}}平均得分最低，为{{constructionInfo.zyMin}}%。
                        </p>
                        <p>
                            ●
                            独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。独体结构的字，
                            {{constructionInfo.dtMaxName}}年级平均得分最高，为{{constructionInfo.dtMax}}%，{{constructionInfo.dtMinName}}平均得分最低，为{{constructionInfo.dtMin}}%。
                        </p>
                        <p>
                            ●
                            上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。因此需要书写者所关注的要点多，书写时容易有忽视。上下结构的字，
                            {{constructionInfo.sxMaxName}}年级平均得分最高，为{{constructionInfo.sxMax}}%，{{constructionInfo.sxMinName}}平均得分最低，为{{constructionInfo.sxMin}}%。
                        </p>
                        <div class="item_title">二、项目背景</div>
                        <p> 经研究表明，学生识写能力与精细运动能力和综合学习能力发展密切相关，为了解学生汉字的识写能力水平，
                            特引进首都师范大学专业团队设计并开展规范汉字书写指导工作。前期需先对学生基础水平，常见问题进行一项测评，并于学期末进行后测，形成对比。
                            形式采用线下测评的方式，组织学生进行一套测试题的书写，不同年级书写量有所不同，书写时间10-30分钟左右。
                            首师大专家会针对学生的情况出具详细的测评报告，为后续开展有针对性地指导打好基础。
                        </p>
                        <div class="item_title">三、试卷设计</div>
                        <p>本次后期检测试卷由首都师范大学“双优云桥”教育服务中心统一命题，内容覆盖面广，
                            重点突出，具有一定的代表性；试卷题量适中，由易到难，具备一定的层次性。
                        </p>
                        <p>根据每个年级学生认知水平进行不同内容与考察点，同时试卷内容设计既重视对基础书写规范的考查，同时注重对学生汉字识字及应用能力的培养、归纳，
                            能较为全面的检测参与测试的学生对已有汉字识写基础知识的掌握情况。</p>
                        <p>识字方面，通过拼音、组词、成语及笔顺（根据年级有所侧重）等角度考察学生对汉字的认知；写字方面，通过日常书写、刻意书写两个角度来测评学生的书写水平。</p>
                        <div class="item_title">四、开展过程</div>
                        <p>本次后测，采用统一命题，使用首都师范大学专业技术团队研发的AI智能评价技术进行统一测评，能够准确评价学生识写能力，并做出量化分析。</p>
                        <p>开展过程包括以下几个阶段：</p>
                        <p>（一）准备阶段</p>
                        <p>印刷书写材料及信息识别码。</p>
                        <p>（二）书写阶段</p>
                        <p>学生在一定时间内，进行后测书写，可使用黑色铅笔、钢笔、水性笔，保持卷面整洁、书写完整清晰。</p>
                        <p>（三）测评阶段</p>
                        <p>回收试卷，并对试卷进行扫描入库，系统识别及对每个字的评价。</p>
                        <p>（四）总结报告</p>
                        <p>针对评价结果，产生对应的测评报告。</p>
                        <div class="item_title">五、参与情况</div>
                        <p>为在本校精准、高效的开展关于规范汉字书写的常规教学活动，由首都师范大学首师优字团队，对我校在校学生集中进行了规范汉字识写能力后期检测。经过试卷分析统计，本次后测活动共识别到{{allData.count}}个学生参与，
                            共回收到有效的后测试卷{{allData.result}}页。共收集到各年级试卷样本数量如下：</p>
                        <table border="1">
                            <tr>
                                <th colspan="5">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>年级班级</td>
                                <td>参与总人数</td>
                                <td>总回收试卷页数</td>
                                <td>有效试卷页数</td>
                                <td>空白卷/污损/不清晰导致测评失败</td>
                            </tr>
                            <tr v-for="(item,index) in tabalAll" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.allPage}}</td>
                                <td>{{item.result}}</td>
                                <td>{{item.failPage}}</td>
                            </tr>
                        </table>
                        <p style="font-size:14px">
                            *为提高识别率及成绩的完整性，建议同学们在测评过程中，内容书写完整，字迹清晰，试卷整洁，尽量不涂鸦，保持四周边线及二维码等清晰无遮挡（需要粘贴条码，请在引导框整正确粘贴个人信息条码）。
                        </p>
                        <div class="item_title">六、测评结果分析</div>
                        <p>（一）书写习惯</p>
                        <p>●握笔姿势</p>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。</p>
                        <p>（1）全校学生握笔姿势整体分布 </p>
                        <p>关于本校学生握笔姿势的调查，各年级选择的人数分布如下：</p>
                        <table border="1">
                            <tr>
                                <td>年级</td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿1
                                        <img style="width:100px" src="../../assets/report/u0.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿2
                                        <img style="width:100px" src="../../assets/report/u1.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿3(正确)
                                        <img style="width:100px" src="../../assets/report/u2.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿4
                                        <img style="width:100px" src="../../assets/report/u3.jpg" alt="">
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(item,index) in woziAll" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.posture_one}}</td>
                                <td>{{item.posture_two}}</td>
                                <td>{{item.posture_three}}</td>
                                <td>{{item.posture_four}}</td>
                            </tr>
                        </table>
                        *（注：本次调查可能出现学生选择多项）
                        <!-- 全校学生握笔姿势分布饼图 -->
                        <div class="myChart" id='myChart7'></div>
                        <p>根据调查结果，学生选择正确的握笔姿势（握姿3）总人数为{{holdInfo.rightNnm}}人，占全校学生的{{holdInfo.rightRatio}}%；
                            学生选择错误握笔姿势（选择：握姿1，握姿2，握姿4）的共有{{holdInfo.failNum}}人，占全校学生的{{holdInfo.faliRatio}}%。</p>
                        <p>（2）各年级学生握笔姿势正确率 </p>
                        <!-- 各年级学生握笔姿势正确率 -->
                        <div class="myChart" id="myChart8"></div>
                        <p>根据调查结果可见，{{holdInfo.highName}}握笔正确率最高占{{holdInfo.highRatio}}%；{{holdInfo.lowName}}握笔正确率最低，为{{holdInfo.lowRatio}}%。
                        </p>
                        <p>（3）全校学生错误握笔姿势分布</p>
                        <div class="myChart" id='myChart9'></div>
                        <p>根据数据分析：</p>
                        <p>（1）学生选择握姿1的共有{{holdInfo.hold_1}}人，占错误人数的{{holdInfo.ratio_1}}%，该错误握笔姿势是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；
                        </p>
                        <p>（2）选择握姿2的有{{holdInfo.hold_2}}人，占错误人数的{{holdInfo.ratio_2}}%，该错误握笔姿势是把拇指藏在食指后面，是普遍易犯错误的执笔手势；
                        </p>
                        <p>（3）握姿4的有{{holdInfo.hold_4}}人，占错误人数的{{holdInfo.ratio_4}}%，该错误握笔姿势是手腕内钩，笔向自己，会造成书写角度的错误，同时这种执笔方法会影响脊骨健康。
                        </p>
                        <p>握姿1,2,4都是错误的，这些执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感，应予以重视。</p>
                        <p>（4）各年级学生错误握笔姿势分布</p>
                        <div class="myChart" id='myChart10'></div>
                        <p>按照年级统计如图，各年级四种握笔姿势分别的人数统计如下：整体来看错误的握笔姿势中，握笔姿势{{holdInfo.mostFail}}所占人数最多，应引起重视。</p>
                        <p>针对握笔错误的情况，建议教师可以在每节书法课程开始之前，在平台上打开首师大专家录制的握笔教学视频及《握笔儿歌》，指导学生练习握笔姿势，养成良好的书写习惯。</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>《握笔儿歌》</span>
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <p>●坐姿</p>
                        <p>（1）全校学生坐姿整体分布 </p>
                        <p>关于本校学生坐姿的调查，各年级选择的人数分布如下：</p>
                        <table border="1">
                            <tr>
                                <td>年级</td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿1
                                        <img style="width:100px" src="../../assets/report/u4.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿2
                                        <img style="width:100px" src="../../assets/report/u5.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿3
                                        <img style="width:100px" src="../../assets/report/u6.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿4(正确)
                                        <img style="width:100px" src="../../assets/report/u7.jpg" alt="">
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(item,index) in sitAll" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.sit_one}}</td>
                                <td>{{item.sit_two}}</td>
                                <td>{{item.sit_three}}</td>
                                <td>{{item.sit_four}}</td>
                            </tr>
                        </table>
                        *（注：本次调查可能出现学生选择多项）
                        <div class="myChart" id='myChart11'></div>
                        <p>根据调查结果，学生选择正确的坐姿（坐姿4）总人数为{{sitInfo.rightNum}}人，占全校学生的{{sitInfo.rightRatio}}%；
                            学生选择错误坐姿（坐姿1，2，3）的共有{{sitInfo.failNum}}人，占全校学生的{{sitInfo.faliRatio}}%。</p>
                        <p>（2）各年级学生坐姿正确率 </p>
                        <div class="myChart" id="myChart12"></div>
                        <p>根据调查结果可见，{{sitInfo.highName}}坐姿正确率最高占{{sitInfo.highRatio}}%；{{sitInfo.lowName}}坐姿正确率最低，为{{sitInfo.lowRatio}}%。
                        </p>
                        <p>（3）全校学生错误坐姿分布 </p>
                        <div class="myChart" id='myChart13'></div>
                        <p>（1）学生选择坐姿1的共有{{sitInfo.hold_1}}人，占{{sitInfo.ratio_1}}%，该坐姿学生趴在桌子上写字，可能会引起驼背、近视等问题；</p>
                        <p>（2）选择坐姿2的有{{sitInfo.hold_2}}人，占{{sitInfo.ratio_2}}%，该坐姿学生身体倾向一侧，可能会引起脊柱侧弯等问题；</p>
                        <p>（3）坐姿3的有{{sitInfo.hold_4}}人，占{{sitInfo.ratio_4}}%，该坐姿学生身体贴着桌子，可能会造成不稳定，且影响视力。</p>
                        <p>前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。</p>
                        <p>（4）各年级学生错误坐姿分布 </p>
                        <div class="myChart" id='myChart14'></div>
                        <p>按照年级统计如图，各年级四种坐姿姿势分别的人数统计结果，整体来看错误的坐姿中，{{sitInfo.mostFail}}所占人数最多，应引起重视。</p>
                        <p>出现错误坐姿的主要问题是：学生趴在桌子上写字、身体倾向一侧，或者靠着桌子侧，这样可能会引起驼背、近视、脊柱侧弯等问题，需要予以指导和纠正。正确的坐姿最常用的就是“三个一”：胸离桌子一拳远，眼离书本一尺远，手离笔尖一寸远。可以选择可调节高度和倾角的书桌来帮助孩子养成正确坐姿。
                        </p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/sitdefault.png">
                        </div>
                        <p>（二）书写基础</p>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、例字相似性、占格一致性、布局整齐度四个维度进行分析。</p>
                        <p>其中，作品完整度指整个篇幅中，正确书写内容所占的百分比；例字相似性指所写的字与示范字的相似度；占格一致性指所有书写内容书写大小占格的偏差情况；布局整齐度指整个篇幅中，所有字的上下左右对齐的情况。根据各维度学生成绩的分布情况，划分为三个分数段进行统计，各年级学生具体得分情况分析如下：
                        </p>
                        <p>●作品完整度</p>
                        <p>
                            考察学生在书写时能够完整正确书写的情况，涂画或错别字会造成成绩的偏差。本校在作品完整度各分数段整体分布情况如下：
                            A(80-100)占{{allInfo.all_wzd_a}}%；B(65-80)占{{allInfo.all_wzd_b}}%；C(0-65)占{{allInfo.all_wzd_c}}%。
                            整体来看，各分数段中，{{allInfo.all_wzd_high}}等级的占比最高，该分数段中{{allInfo.all_wzd_HClass}}平均完整度最高，{{allInfo.all_wzd_LClass}}平均完整度最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart17'></div>
                        <p>●例字相似性</p>
                        <p>
                            考察后测中学生书写与例字的相似程度，本校在例字相似性各分数段整体分布情况如下：
                            A(80-100)占{{allInfo.all_xsx_a}}%；B(65-80)占{{allInfo.all_xsx_b}}%；C(0-65)占{{allInfo.all_xsx_c}}%。
                            整体来看，各分数段中，{{allInfo.all_xsx_high}}等级的占比最高，该分数段中{{allInfo.all_xsx_HClass}}平均相似性最高，{{allInfo.all_xsx_LClass}}平均相似性最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart18'></div>
                        <p>●占格一致性</p>
                        <p>
                            考察学生书写时生字在格子中的占格大小情况，本校在占格一致性各分数段整体分布情况如下：
                            A(80-100)占{{allInfo.all_yzx_a}}%；B(65-80)占{{allInfo.all_yzx_b}}%；C(0-65)占{{allInfo.all_yzx_c}}%。
                            整体来看，各分数段中，{{allInfo.all_yzx_high}}等级的占比最高，该分数段中{{allInfo.all_yzx_HClass}}平均相似性最高，{{allInfo.all_yzx_LClass}}平均相似性最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart19'></div>
                        <p>●布局整齐度</p>
                        <p>
                            考察学生在书写时篇章内容之间整齐书写的情况，本校在布局整齐度各分数段整体分布情况如下：
                            A(80-100)占{{allInfo.all_zqd_a}}%；B(65-80)占{{allInfo.all_zqd_b}}%；C(0-65)占{{allInfo.all_zqd_c}}%。
                            整体来看，各分数段中，{{allInfo.all_zqd_high}}等级的占比最高，该分数段中{{allInfo.all_zqd_HClass}}平均相似性最高，{{allInfo.all_zqd_LClass}}平均相似性最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart20'></div>
                        <p>●四个维度的对比</p>
                        <p>
                            在后测中，关于学生书写基础，通过综合对比各年级四个书写基础维度成绩的平均值，注重临写内容正确、完整，标点使用规范；行列排布协调，横成行、
                            纵成列，单字大小一致，占格位置居中、不偏不倚；行文书写流畅、自然，一气呵成；笔法规范、用笔到位，字法符合结构特征。
                        </p>
                        <p>
                            针对后测样本数据的分析，其中各个维度的测评能力平均水平：
                            作品完整度平均分为{{allInfo.all_wzd}}%，{{allInfo.all_wzd>82.42?'高':'低'}}于全国常模82.42%；
                            例字相似性平均分为{{allInfo.all_xsx}}%，{{allInfo.all_xsx>79.82?'高':'低'}}于全国常模79.82%；
                            占格一致性平均分为{{allInfo.all_yzx}}%，{{allInfo.all_yzx>74.45?'高':'低'}}于全国常模74.45%；
                            布局整齐度均分为{{allInfo.all_zqd}}%，{{allInfo.all_zqd>73.40?'高':'低'}}于全国常模73.40%。
                        </p>
                        <table border="1">
                            <tr>
                                <td>书写维度</td>
                                <td>考核要求</td>
                                <td>后测平均水平</td>
                                <td>全国常模</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>内容完整、用字正确，标点符号使用精准</td>
                                <td>{{allInfo.all_wzd}}%</td>
                                <td>82.42%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>笔法正确，字法规范，与规范例字书写相似</td>
                                <td>{{allInfo.all_xsx}}%</td>
                                <td>79.82%</td>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>书写自然流畅，大小占格合理</td>
                                <td>{{allInfo.all_yzx}}%</td>
                                <td>74.45%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>排布协调，横成行、纵成列，占格居中</td>
                                <td>{{allInfo.all_zqd}}%</td>
                                <td>73.40%</td>
                            </tr>
                        </table>
                        <p>整体来看，学生具备一定的书写基础，相较于全国常模，建议参与测评的学生在例字相似性和布局整齐度两方面需再度提升。如图：</p>
                        <div class="myChart" id="myChart21"></div>
                        <p>（1）作品完整度平均分为{{allInfo.all_wzd}}%，{{allInfo.all_wzd>82.42?'高':'低'}}于全国常模82.42%，{{allComments.wzd[comparisonWd(allInfo.all_wzd)]}}
                        </p>
                        <p>（2）例字相似性平均分为{{allInfo.all_xsx}}%，{{allInfo.all_xsx>79.82?'高':'低'}}于全国常模79.82%，{{allComments.xsx[comparisonWd(allInfo.all_xsx)]}}
                        </p>
                        <p>（3）占格一致性平均分为{{allInfo.all_yzx}}%，{{allInfo.all_yzx>74.45?'高':'低'}}于全国常模74.45%，{{allComments.yzx[comparisonWd(allInfo.all_yzx)]}}
                        </p>
                        <p>（4）布局整齐度均分为{{allInfo.all_zqd}}%，{{allInfo.all_zqd>73.40?'高':'低'}}于全国常模73.40%，{{allComments.zqd[comparisonWd(allInfo.all_zqd)]}}
                        </p>
                        <p>（三）书写辨析</p>
                        <p>
                            通过二、三、四题考察音形义、结构、笔顺笔画等书写辨析能力，
                            其中在生字音形义辨析中，{{computedMinVal(zqlTable.zql_q2).name}}正确率最低为{{computedMinVal(zqlTable.zql_q2).value}}%；
                            在不同结构生字辨析中，{{computedMinVal(zqlTable.zql_q3).name}}正确率最低为{{computedMinVal(zqlTable.zql_q3).value}}%；
                            在不同笔顺笔画生字辨析中，{{computedMinVal(zqlTable.zql_q4).name}}正确率最低为{{computedMinVal(zqlTable.zql_q4).value}}%。
                        </p>
                        <p>书写辨析详细数据如下：</p>
                        <table border="1">
                            <tr>
                                <th style="width:80px">题目</th>
                                <th>辨析内容</th>
                                <th v-for="(item,index) in zqlTable.zql_name" :key="index">{{item}}正确率</th>
                            </tr>
                            <tr>
                                <td style="width:80px">第二题</td>
                                <td>生字音形义辨析</td>
                                <td v-for="(item,index) in zqlTable.zql_q2" :key="index">{{item.value}}%</td>
                            </tr>
                            <tr>
                                <td style="width:80px">第三题</td>
                                <td>不同结构生字辨析</td>
                                <td v-for="(item,index) in zqlTable.zql_q3" :key="index">{{item.value}}%</td>
                            </tr>
                            <tr>
                                <td style="width:80px">第四题</td>
                                <td>不同笔顺笔画生字辨析</td>
                                <td v-for="(item,index) in zqlTable.zql_q4" :key="index">{{item.value}}%</td>
                            </tr>
                        </table>
                        <div class="myChart" id='myChart22'></div>
                        <p>
                            按照汉字结构划分，根据不同结构生字书写相似性的测评情况分析得出：
                            左右结构的生字，{{constructionInfo.zyMinName}}平均得分最低；
                            上下结构的字，{{constructionInfo.sxMinName}}平均得分最低；
                            独体结构的字，{{constructionInfo.dtMinName}}平均得分最低；
                            包围结构的字，{{constructionInfo.bwMinName}}平均得分最低。
                        </p>
                        <table border="1">
                            <tr>
                                <th>汉字结构</th>
                                <th v-for="(item,index) in jgTable.class" :key="index">{{item}}</th>
                            </tr>
                            <tr>
                                <td>左右结构</td>
                                <td v-for="(item,index) in jgTable.zy_data" :key="index">{{item}}%</td>
                            </tr>
                            <tr>
                                <td>上下结构</td>
                                <td v-for="(item,index) in jgTable.sx_data" :key="index">{{item}}%</td>
                            </tr>
                            <tr>
                                <td>独体结构</td>
                                <td v-for="(item,index) in jgTable.dt_data" :key="index">{{item}}%</td>
                            </tr>
                            <tr>
                                <td>包围结构</td>
                                <td v-for="(item,index) in jgTable.bw_data" :key="index">{{item}}%</td>
                            </tr>
                        </table>
                        <div class="myChart" id="myChart23"></div>
                        <p>
                            （1）包围结构字形，在书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。包围结构的字，
                            {{constructionInfo.bwMaxName}}年级平均得分最高，为{{constructionInfo.bwMax}}%，{{constructionInfo.bwMinName}}平均得分最低，为{{constructionInfo.bwMin}}%。
                        </p>
                        <p>
                            （2）左右结构在常用汉字中的占比高达63.7%，此次后测中，学生对属于左右结构的单字，包括其部件与部件之间的位置、大小及高低排布等关系处理上，技巧运用更为熟练。左右结构的生字，
                            {{constructionInfo.zyMaxName}}年级平均得分最高，为{{constructionInfo.zyMax}}%，{{constructionInfo.zyMinName}}平均得分最低，为{{constructionInfo.zyMin}}%。
                        </p>
                        <p>
                            （3）独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。独体结构的字，
                            {{constructionInfo.dtMaxName}}年级平均得分最高，为{{constructionInfo.dtMax}}%，{{constructionInfo.dtMinName}}平均得分最低，为{{constructionInfo.dtMin}}%。
                        </p>
                        <p>
                            （4）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。因此需要书写者所关注的要点多，书写时容易有忽视。上下结构的字，
                            {{constructionInfo.sxMaxName}}年级平均得分最高，为{{constructionInfo.sxMax}}%，{{constructionInfo.sxMinName}}平均得分最低，为{{constructionInfo.sxMin}}%。
                        </p>
                        <p>（四）书写应用</p>
                        <p>
                            日常书写，是指学生在做题或完成作业过程中书写的内容，日常书写速度相对较快，一部分精力用在理解题意，从而不会刻意的使用书写技巧情况下的书写考察，叫做日常书写；刻意书写，是通过设定指定临写内容进行的书写，有目的的进行书写布局与技巧考察。
                        </p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th v-for="(item,index) in jgTable.class" :key="index">{{item}}</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td v-for="(item,index) in pjwdTable.rc_data" :key="index">{{item.value}}%</td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td v-for="(item,index) in pjwdTable.ky_data" :key="index">{{item.value}}%</td>
                            </tr>
                        </table>
                        <div class="myChart" id="myChart24"></div>
                        <p>
                            根据后测数据分析，从例字相似度分析，学生刻意书写平均成绩{{pjwdTable.compare}}于日常书写。
                            {{pjwdTable.comment}}
                        </p>

                        <div class="item_title">各年级具体分析如下：</div>
                    </div>
                </div>
                <div class="first_grade flex-column-center" id="一年级" v-if="JSON.stringify(gradeOne) !== '{}'">
                    <div class="school">{{schoolName}}一年级</div>
                    <div class="name">学生识写能力后测分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、参与情况</div>
                        <p>
                            截止目前，本轮后测中，共计收到来自{{schoolName}}一年级{{gradeOne.dimension_f_6.count}}位同学提交的{{gradeOne.dimension_f_6.result}}页试卷。
                        </p>
                        <table border="1">
                            <tr>
                                <th colspan="3">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>班级</td>
                                <td>参与总人数</td>
                                <td>回收试卷</td>
                            </tr>
                            <tr v-for="(item,index) in tabalOne" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </table>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、整体分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{gradeOne.dimension_a_1.result}}人，占{{(gradeOne.dimension_a_1.result*100/gradeOne.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeOne.dimension_a_2.result}}人，占{{(gradeOne.dimension_a_2.result*100/gradeOne.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeOne.dimension_a_3.result}}人，占{{(gradeOne.dimension_a_3.result*100/gradeOne.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeOne.dimension_a_4.result}}人，占{{(gradeOne.dimension_a_4.result*100/gradeOne.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{gradeOne.dimension_b_1.result}}人，占{{(gradeOne.dimension_b_1.result*100/gradeOne.dimension_b_1.count).toFixed(2)}}%；
                            坐姿2的有{{gradeOne.dimension_b_2.result}}人，占{{(gradeOne.dimension_b_2.result*100/gradeOne.dimension_b_2.count).toFixed(2)}}%；
                            坐姿3的有{{gradeOne.dimension_b_3.result}}人，占{{(gradeOne.dimension_b_3.result*100/gradeOne.dimension_b_3.count).toFixed(2)}}%；
                            坐姿4的有{{gradeOne.dimension_b_4.result}}人，占{{(gradeOne.dimension_b_4.result*100/gradeOne.dimension_b_4.count).toFixed(2)}}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four">
                        <div class="items">（二）书写基础</div>
                        <p>针对学生写字，我们分为2个场景考察，“日常书写”与“刻意书写”两方面。其中日常书写是要了解学生在日常练习作业等场景快速书写的情况，目的是表现其自然的书写状态；
                            而刻意书写，则是学生集中精力进行规范书写练习，侧重于书写技法考察。</p>
                        <div class="items">1.日常书写</div>
                        <p>日常书写考察，主要通过成语填充、结构组字或归类、笔顺识别写字等场景进行考察，体现在第二、三、四题，我们从例字相似性和占格一致性角度进行分析如下：</p>
                        <table border="1">
                            <tr>
                                <th>题目</th>
                                <th>生字</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                                <th>1~2年级例字相似性全国常模</th>
                                <th>1~2年级占格一致性全国常模</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>主、各、因、多</td>
                                <td>{{(gradeOne.dimension_d_1.result/gradeOne.dimension_d_1.count).toFixed(2)}}%</td>
                                <td>{{(gradeOne.dimension_e_1.result/gradeOne.dimension_e_1.count).toFixed(2)}}%</td>
                                <td rowspan="4">78%</td>
                                <td rowspan="4">76%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>思、晴、爸、居</td>
                                <td>{{(gradeOne.dimension_d_2.result/gradeOne.dimension_d_2.count).toFixed(2)}}%</td>
                                <td>{{(gradeOne.dimension_e_2.result/gradeOne.dimension_e_2.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>校、走、光、之</td>
                                <td>{{(gradeOne.dimension_d_3.result/gradeOne.dimension_d_3.count).toFixed(2)}}%</td>
                                <td>{{(gradeOne.dimension_e_3.result/gradeOne.dimension_e_3.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td colspan="2">平均分值</td>
                                <td>{{(gradeOne.dimension_f_4.result/gradeOne.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeOne.dimension_f_5.result/gradeOne.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <p>从例字相似性角度，一年级学生在日常书写中例字相似性平均水平达到{{(gradeOne.dimension_f_4.result/gradeOne.dimension_f_4.count).toFixed(2)}}%，
                            {{(gradeOne.dimension_f_4.result/gradeOne.dimension_f_4.count).toFixed(2)>78?'高':'低'}}于全国常模78%
                            ；占格一致性平均值{{(gradeOne.dimension_f_5.result/gradeOne.dimension_f_5.count).toFixed(2)}}%，
                            {{(gradeOne.dimension_f_5.result/gradeOne.dimension_f_5.count).toFixed(2)>76?'高':'低'}}于全国常模76%。
                        </p>
                        <div class="items">2.刻意书写</div>
                        <img src="../../assets/reportAfter/1-5.png">
                        <p>通过第五题让学生临写指定的内容，考察学生的“刻意书写”水平。我们对书写内容采用整体测评方式对内容的作品完整度、整体占格一致性、例字相似性、布局整齐度四个维度进行分析。
                            发现学生整体书写水平如下：</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>1~2年级全国常模</th>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>{{(gradeOne.dimension_e_4.result/gradeOne.dimension_e_4.count).toFixed(2)}}%</td>
                                <td>76%</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>{{(gradeOne.dimension_c_4.result*100/gradeOne.dimension_c_4.count).toFixed(2)}}%
                                </td>
                                <td>86%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>{{(gradeOne.dimension_d_4.result/gradeOne.dimension_d_4.count).toFixed(2)}}%</td>
                                <td>78%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>{{(gradeOne.dimension_f_1.result/gradeOne.dimension_f_1.count).toFixed(2)}}%</td>
                                <td>75%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <div class="myChart" id="myChart"></div>
                        <p>本年级学生中，刻意书写练习作品：</p>
                        <p>
                            1.整体水平为良好作品共{{gradeOne.dimension_f_2.result}}份，占总数的{{(gradeOne.dimension_f_2.result*100/gradeOne.dimension_f_2.count).toFixed(2)}}%，
                            优秀作品{{gradeOne.dimension_f_3.result}}份，占总人数的{{(gradeOne.dimension_f_3.result*100/gradeOne.dimension_f_3.count).toFixed(2)}}%；
                        </p>
                        <p>2.作品平均完整度为{{gradeOneFull}}%，{{gradeOneFull>=80?'篇章完整，内容正确。':gradeOneFull>=60&&gradeOneFull<80?'篇章较完整。':'篇章不够完整，要正确的书写所有内容。'}}
                        </p>
                        <p>3.卷面布局整齐度为{{gradeOneLayout}}%，{{gradeOneLayout>=80?'布局协调，占格居中。':gradeOneLayout>=60&&gradeOneLayout<80?'布局基本合理，注意重心位置。':'布局不够整齐，注意上下左右保持对齐。'}}
                        </p>
                        <p>4.占格一致性{{gradeOneBeautufy}}%
                            ，{{gradeOneBeautufy>=80?'字的大小适中，书写流畅。':gradeOneBeautufy>=60&&gradeOneBeautufy<80?'书写大小基本均匀，内部空间不足。':'字的书写偏差较大，整体偏大/小，主笔位置需找准。'}}
                        </p>
                        <p>5.例字相似性为{{gradeOneContent}}%，{{gradeOneContent>=80?'书写工整规范，运笔准确。':gradeOneContent>=60&&gradeOneContent<80?'书写基本规范，结构需严谨。':'书写不够规范，部件位置应准确。'}}
                        </p>
                        <div class="items">3.两种书写场景效果对比</div>
                        <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下:</p>
                        <table border="1">
                            <tr>
                                <th>书写场景</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td>{{(gradeOne.dimension_f_4.result/gradeOne.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeOne.dimension_f_5.result/gradeOne.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td>{{pjwdTable.ky_data.filter(item=>{return item.name=='一年级'})[0].value}}%</td>
                                <td>{{(gradeOne.dimension_e_4.result/gradeOne.dimension_e_4.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>由此可见：</p>
                        <p>1）例字相似性角度，作品刻意书写成绩
                            {{(gradeOne.dimension_f_4.result/gradeOne.dimension_f_4.count).toFixed(2)>(gradeOne.dimension_d_4.result/gradeOne.dimension_d_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.like[comparison((gradeOne.dimension_f_4.result/gradeOne.dimension_f_4.count).toFixed(2),(gradeOne.dimension_d_4.result/gradeOne.dimension_d_4.count).toFixed(2))]}}
                        </p>
                        <p>2）占格一致性角度，作品刻意书写成绩
                            {{(gradeOne.dimension_f_5.result/gradeOne.dimension_f_5.count).toFixed(2)>(gradeOne.dimension_e_4.result/gradeOne.dimension_e_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.consistency[comparison((gradeOne.dimension_f_5.result/gradeOne.dimension_f_5.count).toFixed(2),(gradeOne.dimension_e_4.result/gradeOne.dimension_e_4.count).toFixed(2))]}}
                        </p>
                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.拼音识写</div>
                        <img src="../../assets/reportAfter/1-2.png">
                        <p>通过“看拼音，补充笔画”，可以考察学生根据拼音和生字的轮廓认识生字的能力。本阶段学生初识汉字，根据新课标要求，需要具备拼音的学习及基础笔画的认识。</p>
                        <p>
                            通过第一题“看拼音写汉字”，可以考察学生根据拼音和词语辨识生字的能力。同时本题还考察“横”“撇”“捺”“多横等距”“多撇平行”等常用基础笔画及组合规律的掌握，基本笔画的规范性会影响到整体的书写效果，需要进行重要笔画及笔画之间规律的基础练习。通过本题测评发现，四组生字书写中，整体的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='一年级'})[0].value}}%，学生基本具备了从识字到写字的基础准备。
                        </p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/reportAfter/1-3.png">
                        <p>
                            通过“偏旁部首组合生字”，可以考察学生对基本部件的认识，及所整组合生字的认识。能够考察学生对“心字底、日字旁、父字头”等常用偏旁位置及“左右结构、上下结构、包围结构”组合规律的认识。通过测评发现，本题的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='一年级'})[0].value}}%。
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/reportAfter/1-4.png">
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='一年级'})[0].value}}%。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。
                        </p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次后测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{gradeOne.dimension_a_1.result}}人，占{{(gradeOne.dimension_a_1.result*100/gradeOne.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeOne.dimension_a_2.result}}人，占{{(gradeOne.dimension_a_2.result*100/gradeOne.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeOne.dimension_a_3.result}}人，占{{(gradeOne.dimension_a_3.result*100/gradeOne.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeOne.dimension_a_4.result}}人，占{{(gradeOne.dimension_a_4.result*100/gradeOne.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <div class="items">2.掌握基本笔画的写法</div>
                        <p>考察“横”“撇”“捺”“多横等距”“多撇平行”等常用基础笔画及组合规律的掌握，基本笔画的规范性会影响到整体的书写效果，需要进行重要笔画及笔画之间规律的基础练习。</p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>通过偏旁部首组字题，我们考察了不同偏旁部首的书写方式，低年级学生对于生字的组合关系与基本部件的掌握尤为重要。可通过课程学习，系统的积累每一类偏旁部首的书写能力。</p>
                        <div class="items">4.笔顺笔画的掌握</div>
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本卷中通过常见的易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='一年级'})[0].value}}%。
                        </p>
                    </div>
                </div>
                <div class="second_grade flex-column-center" id="二年级" v-if="JSON.stringify(gradeTwo) !== '{}'">
                    <div class="school">{{schoolName}}二年级</div>
                    <div class="name">学生识写能力后测分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、参与情况</div>
                        <p>
                            截止目前，本轮后测中，共计收到来自{{schoolName}}二年级{{gradeTwo.dimension_f_6.count}}位同学提交的{{gradeTwo.dimension_f_6.result}}页试卷。
                        </p>
                        <table border="1">
                            <tr>
                                <th colspan="3">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>班级</td>
                                <td>参与总人数</td>
                                <td>回收试卷</td>
                            </tr>
                            <tr v-for="(item,index) in tabalTwo" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </table>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、整体分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨健康。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{gradeTwo.dimension_a_1.result}}人，占{{(gradeTwo.dimension_a_1.result*100/gradeTwo.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeTwo.dimension_a_2.result}}人，占{{(gradeTwo.dimension_a_2.result*100/gradeTwo.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeTwo.dimension_a_3.result}}人，占{{(gradeTwo.dimension_a_3.result*100/gradeTwo.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeTwo.dimension_a_4.result}}人，占{{(gradeTwo.dimension_a_4.result*100/gradeTwo.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{gradeTwo.dimension_b_1.result}}人，占{{(gradeTwo.dimension_b_1.result*100/gradeTwo.dimension_b_1.count).toFixed(2)}}%；
                            坐姿2的有{{gradeTwo.dimension_b_2.result}}人，占{{(gradeTwo.dimension_b_2.result*100/gradeTwo.dimension_b_2.count).toFixed(2)}}%；
                            坐姿3的有{{gradeTwo.dimension_b_3.result}}人，占{{(gradeTwo.dimension_b_3.result*100/gradeTwo.dimension_b_3.count).toFixed(2)}}%；
                            坐姿4的有{{gradeTwo.dimension_b_4.result}}人，占{{(gradeTwo.dimension_b_4.result*100/gradeTwo.dimension_b_4.count).toFixed(2)}}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four">
                        <div class="items">（二）书写基础</div>
                        <p>针对学生写字，我们分为2个场景考察，“日常书写”与“刻意书写”两方面。其中日常书写是要了解学生在日常练习作业等场景快速书写的情况，目的是表现其自然的书写状态；
                            而刻意书写，则是学生集中精力进行规范书写练习，侧重于书写技法考察。</p>
                        <div class="items">1.日常书写</div>
                        <p>日常书写考察，主要通过成语填充、结构组字或归类、笔顺识别写字等场景进行考察，体现在第二、三、四题，我们从例字相似性和占格一致性角度进行分析如下：</p>
                        <table border="1">
                            <tr>
                                <th>题目</th>
                                <th>生字</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                                <th>1~2年级例字相似性全国常模</th>
                                <th>1~2年级占格一致性全国常模</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>礼、息、暖、菜</td>
                                <td>{{(gradeTwo.dimension_d_1.result/gradeTwo.dimension_d_1.count).toFixed(2)}}%</td>
                                <td>{{(gradeTwo.dimension_e_1.result/gradeTwo.dimension_e_1.count).toFixed(2)}}%</td>
                                <td rowspan="4">78%</td>
                                <td rowspan="4">76%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>邮、桃、启、背</td>
                                <td>{{(gradeTwo.dimension_d_2.result/gradeTwo.dimension_d_2.count).toFixed(2)}}%</td>
                                <td>{{(gradeTwo.dimension_e_2.result/gradeTwo.dimension_e_2.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>柳、甘、匹、与</td>
                                <td>{{(gradeTwo.dimension_d_3.result/gradeTwo.dimension_d_3.count).toFixed(2)}}%</td>
                                <td>{{(gradeTwo.dimension_e_3.result/gradeTwo.dimension_e_3.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td colspan="2">平均分值</td>
                                <td>{{(gradeTwo.dimension_f_4.result/gradeTwo.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeTwo.dimension_f_5.result/gradeTwo.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <p>从例字相似性角度，二年级学生在日常书写中例字相似性平均水平达到{{(gradeTwo.dimension_f_4.result/gradeTwo.dimension_f_4.count).toFixed(2)}}%，
                            {{(gradeTwo.dimension_f_4.result/gradeTwo.dimension_f_4.count).toFixed(2)>78?'高':'低'}}于全国常模78%
                            ；占格一致性平均值{{(gradeTwo.dimension_f_5.result/gradeTwo.dimension_f_5.count).toFixed(2)}}%，
                            {{(gradeTwo.dimension_f_5.result/gradeTwo.dimension_f_5.count).toFixed(2)>76?'高':'低'}}于全国常模76%。
                        </p>
                        <div class="items">2.刻意书写</div>
                        <img src="../../assets/reportAfter/2-5.png">
                        <p>通过第五题让学生临写指定的内容，考察学生的“刻意书写”水平。我们对书写内容采用整体测评方式对内容的作品完整度、整体占格一致性、例字相似性、布局整齐度四个维度进行分析。
                            发现学生整体书写水平如下：</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>1~2年级全国常模</th>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>{{(gradeTwo.dimension_e_4.result/gradeTwo.dimension_e_4.count).toFixed(2)}}%</td>
                                <td>76%</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>{{(gradeTwo.dimension_c_4.result*100/gradeTwo.dimension_c_4.count).toFixed(2)}}%
                                </td>
                                <td>86%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>{{(gradeTwo.dimension_d_4.result/gradeTwo.dimension_d_4.count).toFixed(2)}}%</td>
                                <td>78%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>{{(gradeTwo.dimension_f_1.result/gradeTwo.dimension_f_1.count).toFixed(2)}}%</td>
                                <td>75%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <div class="myChart" id="myChart2"></div>
                        <p>本年级学生中，刻意书写练习作品：</p>
                        <p>
                            1.整体水平为良好作品共{{gradeTwo.dimension_f_2.result}}份，占总数的{{(gradeTwo.dimension_f_2.result*100/gradeTwo.dimension_f_2.count).toFixed(2)}}%，
                            优秀作品{{gradeTwo.dimension_f_3.result}}份，占总人数的{{(gradeTwo.dimension_f_3.result*100/gradeTwo.dimension_f_3.count).toFixed(2)}}%；
                        </p>
                        <p>2.作品平均完整度为{{gradeTwoFull}}%，{{gradeTwoFull>=80?'篇章完整，内容正确。':gradeTwoFull>=60&&gradeTwoFull<80?'篇章较完整。':'篇章不够完整，要正确的书写所有内容。'}}
                        </p>
                        <p>3.卷面布局整齐度为{{gradeTwoLayout}}%，{{gradeTwoLayout>=80?'布局协调，占格居中。':gradeTwoLayout>=60&&gradeTwoLayout<80?'布局基本合理，注意重心位置。':'布局不够整齐，注意上下左右保持对齐。'}}
                        </p>
                        <p>4.占格一致性{{gradeTwoBeautufy}}%
                            ，{{gradeTwoBeautufy>=80?'字的大小适中，书写流畅。':gradeTwoBeautufy>=60&&gradeTwoBeautufy<80?'书写大小基本均匀，内部空间不足。':'字的书写偏差较大，整体偏大/小，主笔位置需找准。'}}
                        </p>
                        <p>5.例字相似性为{{gradeTwoContent}}%，{{gradeTwoContent>=80?'书写工整规范，运笔准确。':gradeTwoContent>=60&&gradeTwoContent<80?'书写基本规范，结构需严谨。':'书写不够规范，部件位置应准确。'}}
                        </p>
                        <div class="items">3.两种书写场景效果对比</div>
                        <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下:</p>
                        <table border="1">
                            <tr>
                                <th>书写场景</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td>{{(gradeTwo.dimension_f_4.result/gradeTwo.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeTwo.dimension_f_5.result/gradeTwo.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td>{{pjwdTable.ky_data.filter(item=>{return item.name=='二年级'})[0].value}}%</td>
                                <td>{{(gradeTwo.dimension_e_4.result/gradeTwo.dimension_e_4.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>由此可见：</p>
                        <p>1）例字相似性角度，作品刻意书写成绩
                            {{(gradeTwo.dimension_f_4.result/gradeTwo.dimension_f_4.count).toFixed(2)>(gradeTwo.dimension_d_4.result/gradeTwo.dimension_d_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.like[comparison((gradeTwo.dimension_f_4.result/gradeTwo.dimension_f_4.count).toFixed(2),(gradeTwo.dimension_d_4.result/gradeTwo.dimension_d_4.count).toFixed(2))]}}
                        </p>
                        <p>2）占格一致性角度，作品刻意书写成绩
                            {{(gradeTwo.dimension_f_5.result/gradeTwo.dimension_f_5.count).toFixed(2)>(gradeTwo.dimension_e_4.result/gradeTwo.dimension_e_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.consistency[comparison((gradeTwo.dimension_f_5.result/gradeTwo.dimension_f_5.count).toFixed(2),(gradeTwo.dimension_e_4.result/gradeTwo.dimension_e_4.count).toFixed(2))]}}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.拼音识写</div>
                        <img src="../../assets/reportAfter/2-2.png">
                        <p>通过“看拼音写汉字”，可以考察学生根据拼音和词语辨识生字的能力。通过本题测评发现，四组生字书写中，整体的平均正确率为
                            {{this.zqlTable.zql_q2.filter(item=>{return item.name=='二年级'})[0].value}}%，学生基本具备了从识字到写字的基础准备。

                        </p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/reportAfter/2-3.png">
                        <p>
                            通过“偏旁部首组合生字”，可以考察学生对基本部件的认识，及所整组合生字的认识。能够考察学生对“右耳刀、木字旁、户字头、月字底”等常用偏旁位置及组合规律的认识。通过测评发现，本题的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='二年级'})[0].value}}%。
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/reportAfter/2-4.png">
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='二年级'})[0].value}}%。学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。
                        </p>
                    </div>

                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次后测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{gradeTwo.dimension_a_1.result}}人，占{{(gradeTwo.dimension_a_1.result*100/gradeTwo.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeTwo.dimension_a_2.result}}人，占{{(gradeTwo.dimension_a_2.result*100/gradeTwo.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeTwo.dimension_a_3.result}}人，占{{(gradeTwo.dimension_a_3.result*100/gradeTwo.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeTwo.dimension_a_4.result}}人，占{{(gradeTwo.dimension_a_4.result*100/gradeTwo.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <div class="items">2.掌握基本笔画的写法</div>
                        <p> 基础笔画，如“撇”、“点”、“竖弯钩”等常用笔画，作为主笔时，所占比重都相对较重，所有基本笔画会影响到整体的书写效果。需要通过简单的字，进行重要笔画的基础练习。</p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>
                            通过偏旁部首组字等题目，我们考察不同偏旁部首的书写“右耳刀、木字旁、户字头、月字底”等的书写，本年级此题的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='二年级'})[0].value}}%。
                            可以通过课程学习，逐渐积累每一类偏旁部首的书写能力。</p>
                    </div>
                </div>
                <div class="third_grade flex-column-center" id="三年级" v-if="JSON.stringify(gradeThree) !== '{}'">
                    <div class="school">{{schoolName}}三年级</div>
                    <div class="name">学生识写能力后测分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、参与情况</div>
                        <p>
                            截止目前，本轮后测中，共计收到来自{{schoolName}}三年级{{gradeThree.dimension_f_6.count}}位同学提交的{{gradeThree.dimension_f_6.result}}页试卷。
                        </p>
                        <table border="1">
                            <tr>
                                <th colspan="3">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>班级</td>
                                <td>参与总人数</td>
                                <td>回收试卷</td>
                            </tr>
                            <tr v-for="(item,index) in tabalThree" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </table>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、整体分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{gradeThree.dimension_a_1.result}}人，占{{(gradeThree.dimension_a_1.result*100/gradeThree.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeThree.dimension_a_2.result}}人，占{{(gradeThree.dimension_a_2.result*100/gradeThree.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeThree.dimension_a_3.result}}人，占{{(gradeThree.dimension_a_3.result*100/gradeThree.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeThree.dimension_a_4.result}}人，占{{(gradeThree.dimension_a_4.result*100/gradeThree.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{gradeThree.dimension_b_1.result}}人，占{{(gradeThree.dimension_b_1.result*100/gradeThree.dimension_b_1.count).toFixed(2)}}%；
                            坐姿2的有{{gradeThree.dimension_b_2.result}}人，占{{(gradeThree.dimension_b_2.result*100/gradeThree.dimension_b_2.count).toFixed(2)}}%；
                            坐姿3的有{{gradeThree.dimension_b_3.result}}人，占{{(gradeThree.dimension_b_3.result*100/gradeThree.dimension_b_3.count).toFixed(2)}}%；
                            坐姿4的有{{gradeThree.dimension_b_4.result}}人，占{{(gradeThree.dimension_b_4.result*100/gradeThree.dimension_b_4.count).toFixed(2)}}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>

                    <div class="part_four">
                        <div class="items">（二）书写基础</div>
                        <p>针对学生写字，我们分为2个场景考察，“日常书写”与“刻意书写”两方面。其中日常书写是要了解学生在日常练习作业等场景快速书写的情况，目的是表现其自然的书写状态；
                            而刻意书写，则是学生集中精力进行规范书写练习，侧重于书写技法考察。</p>
                        <div class="items">1.日常书写</div>
                        <p>日常书写考察，主要通过成语填充、结构组字或归类、笔顺识别写字等场景进行考察，体现在第二、三、四题，我们从例字相似性和占格一致性角度进行分析如下：</p>
                        <table border="1">
                            <tr>
                                <th>题目</th>
                                <th>生字</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                                <th>3~4年级例字相似性全国常模</th>
                                <th>3~4年级占格一致性全国常模</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>南、北、舟、守、兔、语</td>
                                <td>{{(gradeThree.dimension_d_1.result/gradeThree.dimension_d_1.count).toFixed(2)}}%
                                </td>
                                <td>{{(gradeThree.dimension_e_1.result/gradeThree.dimension_e_1.count).toFixed(2)}}%
                                </td>
                                <td rowspan="4">83%</td>
                                <td rowspan="4">79%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>减形、裂尘、闲匀、止尔</td>
                                <td>{{(gradeThree.dimension_d_2.result/gradeThree.dimension_d_2.count).toFixed(2)}}%
                                </td>
                                <td>{{(gradeThree.dimension_e_2.result/gradeThree.dimension_e_2.count).toFixed(2)}}%
                                </td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>痕、释、瓣、拂</td>
                                <td>{{(gradeThree.dimension_d_3.result/gradeThree.dimension_d_3.count).toFixed(2)}}%
                                </td>
                                <td>{{(gradeThree.dimension_e_3.result/gradeThree.dimension_e_3.count).toFixed(2)}}%
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">平均分值</td>
                                <td>{{(gradeThree.dimension_f_4.result/gradeThree.dimension_f_4.count).toFixed(2)}}%
                                </td>
                                <td>{{(gradeThree.dimension_f_5.result/gradeThree.dimension_f_5.count).toFixed(2)}}%
                                </td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <p>
                            从例字相似性角度，三年级学生在日常书写中例字相似性平均水平达到{{(gradeThree.dimension_f_4.result/gradeThree.dimension_f_4.count).toFixed(2)}}%，
                            {{(gradeThree.dimension_f_4.result/gradeThree.dimension_f_4.count).toFixed(2)>84?'高':'低'}}于全国常模84%
                            ；占格一致性平均值{{(gradeThree.dimension_f_5.result/gradeThree.dimension_f_5.count).toFixed(2)}}%，
                            {{(gradeThree.dimension_f_5.result/gradeThree.dimension_f_5.count).toFixed(2)>79?'高':'低'}}于全国常模79%。
                        </p>
                        <div class="items">2.刻意书写</div>
                        <img src="../../assets/reportAfter/3-5.png">
                        <p>通过第五题让学生临写指定的内容，考察学生的“刻意书写”水平。我们对书写内容采用整体测评方式对内容的作品完整度、整体占格一致性、例字相似性、布局整齐度四个维度进行分析。
                            发现学生整体书写水平如下：</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>3~4年级全国常模</th>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>{{(gradeThree.dimension_e_4.result/gradeThree.dimension_e_4.count).toFixed(2)}}%
                                </td>
                                <td>79%</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>{{(gradeThree.dimension_c_4.result*100/gradeThree.dimension_c_4.count).toFixed(2)}}%
                                </td>
                                <td>90%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>{{(gradeThree.dimension_d_4.result/gradeThree.dimension_d_4.count).toFixed(2)}}%
                                </td>
                                <td>83%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>{{(gradeThree.dimension_f_1.result/gradeThree.dimension_f_1.count).toFixed(2)}}%
                                </td>
                                <td>78%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <div class="myChart" id="myChart3"></div>
                        <p>本年级学生中，刻意书写练习作品：</p>
                        <p>
                            1.整体水平为良好作品共{{gradeThree.dimension_f_2.result}}份，占总数的{{(gradeThree.dimension_f_2.result*100/gradeThree.dimension_f_2.count).toFixed(2)}}%，
                            优秀作品{{gradeThree.dimension_f_3.result}}份，占总人数的{{(gradeThree.dimension_f_3.result*100/gradeThree.dimension_f_3.count).toFixed(2)}}%；
                        </p>
                        <p>2.作品平均完整度为{{gradeThreeFull}}%，{{gradeThreeFull>=80?'篇章完整，内容正确。':gradeThreeFull>=60&&gradeThreeFull<80?'篇章较完整。':'篇章不够完整，要正确的书写所有内容。'}}
                        </p>
                        <p>3.卷面布局整齐度为{{gradeThreeLayout}}%，{{gradeThreeLayout>=80?'布局协调，占格居中。':gradeThreeLayout>=60&&gradeThreeLayout<80?'布局基本合理，注意重心位置。':'布局不够整齐，注意上下左右保持对齐。'}}
                        </p>
                        <p>4.占格一致性{{gradeThreeBeautufy}}%
                            ，{{gradeThreeBeautufy>=80?'字的大小适中，书写流畅。':gradeThreeBeautufy>=60&&gradeThreeBeautufy<80?'书写大小基本均匀，内部空间不足。':'字的书写偏差较大，整体偏大/小，主笔位置需找准。'}}
                        </p>
                        <p>5.例字相似性为{{gradeThreeContent}}%，{{gradeThreeContent>=80?'书写工整规范，运笔准确。':gradeThreeContent>=60&&gradeThreeContent<80?'书写基本规范，结构需严谨。':'书写不够规范，部件位置应准确。'}}
                        </p>
                        <div class="items">3.两种书写场景效果对比</div>
                        <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下:</p>
                        <table border="1">
                            <tr>
                                <th>书写场景</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td>{{(gradeThree.dimension_f_4.result/gradeThree.dimension_f_4.count).toFixed(2)}}%
                                </td>
                                <td>{{(gradeThree.dimension_f_5.result/gradeThree.dimension_f_5.count).toFixed(2)}}%
                                </td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td>{{pjwdTable.ky_data.filter(item=>{return item.name=='三年级'})[0].value}}%
                                </td>
                                <td>{{(gradeThree.dimension_e_4.result/gradeThree.dimension_e_4.count).toFixed(2)}}%
                                </td>
                            </tr>
                        </table>
                        <p>由此可见：</p>
                        <p>1）例字相似性角度，作品刻意书写成绩
                            {{(gradeThree.dimension_f_4.result/gradeThree.dimension_f_4.count).toFixed(2)>(gradeThree.dimension_d_4.result/gradeThree.dimension_d_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.like[comparison((gradeThree.dimension_f_4.result/gradeThree.dimension_f_4.count).toFixed(2),(gradeThree.dimension_d_4.result/gradeThree.dimension_d_4.count).toFixed(2))]}}
                        </p>
                        <p>2）占格一致性角度，作品刻意书写成绩
                            {{(gradeThree.dimension_f_5.result/gradeThree.dimension_f_5.count).toFixed(2)>(gradeThree.dimension_e_4.result/gradeThree.dimension_e_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.consistency[comparison((gradeThree.dimension_f_5.result/gradeThree.dimension_f_5.count).toFixed(2),(gradeThree.dimension_e_4.result/gradeThree.dimension_e_4.count).toFixed(2))]}}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.补充成语</div>
                        <img src="../../assets/reportAfter/3-2.png">
                        <p>
                            通过设置语境或补充成语的方式，我们主要考察学生对生字的应用。通过本题测评发现，四组成语补充书写中，学生的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='三年级'})[0].value}}%</p>
                        <div class="items">2.结构认知</div>
                        <img src="../../assets/reportAfter/3-3.png">
                        <p>
                            通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。经过统计，按结构分类的整体平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='三年级'})[0].value}}%。
                            需要注意日常生字间架结构书写特点的理解与记忆，同时容易混淆的结构，需要着重加强。</p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/reportAfter/3-4.png">
                        <p>
                            笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='三年级'})[0].value}}%。
                            学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次后测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{gradeThree.dimension_a_1.result}}人，占{{(gradeThree.dimension_a_1.result*100/gradeThree.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeThree.dimension_a_2.result}}人，占{{(gradeThree.dimension_a_2.result*100/gradeThree.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeThree.dimension_a_3.result}}人，占{{(gradeThree.dimension_a_3.result*100/gradeThree.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeThree.dimension_a_4.result}}人，占{{(gradeThree.dimension_a_4.result*100/gradeThree.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <div class="items">2.掌握汉字含义与应用</div>
                        <p>通过设置语境补充成语的方式，考察学生对生字的应用及字义的掌握。本次调查，四组成语补充书写中，学生的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='三年级'})[0].value}}%。
                            本年级学生对于成语的应用整体较好，请继续保持。</p>
                        <div class="items">3.汉字结构理解</div>
                        <p>通过考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分尤为重要，本卷通过以下汉字选择“裂、减、形、闲、止、尔、尘、匀”等生字进行考察。经过统计
                            ，按结构分类的整体平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='三年级'})[0].value}}%；学生还需要进一步掌握对不同结构的字的理解：
                        </p>
                        <p>1）包围结构字形，在日常书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。</p>
                        <p>2）左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上。</p>
                        <p>3）独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。</p>
                        <p>4）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。
                        </p>
                    </div>
                </div>
                <div class="fourth_grade flex-column-center" id="四年级" v-if="JSON.stringify(gradeFour) !== '{}'">
                    <div class="school">{{schoolName}}四年级</div>
                    <div class="name">学生识写能力后测分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、参与情况</div>
                        <p>
                            截止目前，本轮后测中，共计收到来自{{schoolName}}四年级{{gradeFour.dimension_f_6.count}}位同学提交的{{gradeFour.dimension_f_6.result}}页试卷。
                        </p>
                        <table border="1">
                            <tr>
                                <th colspan="3">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>班级</td>
                                <td>参与总人数</td>
                                <td>回收试卷</td>
                            </tr>
                            <tr v-for="(item,index) in tabalFour" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </table>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、整体分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{gradeFour.dimension_a_1.result}}人，占{{(gradeFour.dimension_a_1.result*100/gradeFour.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeFour.dimension_a_2.result}}人，占{{(gradeFour.dimension_a_2.result*100/gradeFour.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeFour.dimension_a_3.result}}人，占{{(gradeFour.dimension_a_3.result*100/gradeFour.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeFour.dimension_a_4.result}}人，占{{(gradeFour.dimension_a_4.result*100/gradeFour.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{gradeFour.dimension_b_1.result}}人，占{{(gradeFour.dimension_b_1.result*100/gradeFour.dimension_b_1.count).toFixed(2)}}%；
                            坐姿2的有{{gradeFour.dimension_b_2.result}}人，占{{(gradeFour.dimension_b_2.result*100/gradeFour.dimension_b_2.count).toFixed(2)}}%；
                            坐姿3的有{{gradeFour.dimension_b_3.result}}人，占{{(gradeFour.dimension_b_3.result*100/gradeFour.dimension_b_3.count).toFixed(2)}}%；
                            坐姿4的有{{gradeFour.dimension_b_4.result}}人，占{{(gradeFour.dimension_b_4.result*100/gradeFour.dimension_b_4.count).toFixed(2)}}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>

                    <div class="part_four">
                        <div class="items">（二）书写基础</div>
                        <p>针对学生写字，我们分为2个场景考察，“日常书写”与“刻意书写”两方面。其中日常书写是要了解学生在日常练习作业等场景快速书写的情况，目的是表现其自然的书写状态；
                            而刻意书写，则是学生集中精力进行规范书写练习，侧重于书写技法考察。</p>
                        <div class="items">1.日常书写</div>
                        <p>日常书写考察，主要通过成语填充、结构组字或归类、笔顺识别写字等场景进行考察，体现在第二、三、四题，我们从例字相似性和占格一致性角度进行分析如下：</p>
                        <table border="1">
                            <tr>
                                <th>题目</th>
                                <th>生字</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                                <th>3~4年级例字相似性全国常模</th>
                                <th>3~4年级占格一致性全国常模</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>山、水、龙、楼、立、迟</td>
                                <td>{{(gradeFour.dimension_d_1.result/gradeFour.dimension_d_1.count).toFixed(2)}}%</td>
                                <td>{{(gradeFour.dimension_e_1.result/gradeFour.dimension_e_1.count).toFixed(2)}}%</td>
                                <td rowspan="4">83%</td>
                                <td rowspan="4">79%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>稀赖、茅率、疾序、末叉</td>
                                <td>{{(gradeFour.dimension_d_2.result/gradeFour.dimension_d_2.count).toFixed(2)}}%</td>
                                <td>{{(gradeFour.dimension_e_2.result/gradeFour.dimension_e_2.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>吼、杂、慰、蜓</td>
                                <td>{{(gradeFour.dimension_d_3.result/gradeFour.dimension_d_3.count).toFixed(2)}}%</td>
                                <td>{{(gradeFour.dimension_e_3.result/gradeFour.dimension_e_3.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td colspan="2">平均分值</td>
                                <td>{{(gradeFour.dimension_f_4.result/gradeFour.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeFour.dimension_f_5.result/gradeFour.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <p>从例字相似性角度，四年级学生在日常书写中例字相似性平均水平达到{{(gradeFour.dimension_f_4.result/gradeFour.dimension_f_4.count).toFixed(2)}}%，
                            {{(gradeFour.dimension_f_4.result/gradeFour.dimension_f_4.count).toFixed(2)>83?'高':'低'}}于全国常模83%
                            ；占格一致性平均值{{(gradeFour.dimension_f_5.result/gradeFour.dimension_f_5.count).toFixed(2)}}%，
                            {{(gradeFour.dimension_f_5.result/gradeFour.dimension_f_5.count).toFixed(2)>79?'高':'低'}}于全国常模79%。
                        </p>
                        <div class="items">2.刻意书写</div>
                        <img src="../../assets/reportAfter/4-5.png">
                        <p>通过让学生临写指定的内容，考察学生的“刻意书写”水平。我们对书写内容采用整体测评方式对内容的作品完整度、整体占格一致性、例字相似性、布局整齐度四个维度进行分析。发现学生整体书写水平如下：
                        </p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>3~4年级全国常模</th>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>{{(gradeFour.dimension_e_4.result/gradeFour.dimension_e_4.count).toFixed(2)}}%</td>
                                <td>79%</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>{{(gradeFour.dimension_c_4.result*100/gradeFour.dimension_c_4.count).toFixed(2)}}%
                                </td>
                                <td>90%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>{{(gradeFour.dimension_d_4.result/gradeFour.dimension_d_4.count).toFixed(2)}}%</td>
                                <td>83%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>{{(gradeFour.dimension_f_1.result/gradeFour.dimension_f_1.count).toFixed(2)}}%</td>
                                <td>78%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <div class="myChart" id="myChart4"></div>
                        <p>本年级学生中，刻意书写练习作品：</p>
                        <p>
                            1.整体水平为良好作品共{{gradeFour.dimension_f_2.result}}份，占总数的{{(gradeFour.dimension_f_2.result*100/gradeFour.dimension_f_2.count).toFixed(2)}}%，
                            优秀作品{{gradeFour.dimension_f_3.result}}份，占总人数的{{(gradeFour.dimension_f_3.result*100/gradeFour.dimension_f_3.count).toFixed(2)}}%；
                        </p>
                        <p>2.作品平均完整度为{{gradeFourFull}}%，{{gradeFourFull>=80?'篇章完整，内容正确。':gradeFourFull>=60&&gradeFourFull<80?'篇章较完整。':'篇章不够完整，要正确的书写所有内容。'}}
                        </p>
                        <p>3.卷面布局整齐度为{{gradeFourLayout}}%，{{gradeFourLayout>=80?'布局协调，占格居中。':gradeFourLayout>=60&&gradeFourLayout<80?'布局基本合理，注意重心位置。':'布局不够整齐，注意上下左右保持对齐。'}}
                        </p>
                        <p>4.占格一致性{{gradeFourBeautufy}}%
                            ，{{gradeFourBeautufy>=80?'字的大小适中，书写流畅。':gradeFourBeautufy>=60&&gradeFourBeautufy<80?'书写大小基本均匀，内部空间不足。':'字的书写偏差较大，整体偏大/小，主笔位置需找准。'}}
                        </p>
                        <p>5.例字相似性为{{gradeFourContent}}%，{{gradeFourContent>=80?'书写工整规范，运笔准确。':gradeFourContent>=60&&gradeFourContent<80?'书写基本规范，结构需严谨。':'书写不够规范，部件位置应准确。'}}
                        </p>
                        <div class="items">3.两种书写场景效果对比</div>
                        <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下:</p>
                        <table border="1">
                            <tr>
                                <th>书写场景</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td>{{(gradeFour.dimension_f_4.result/gradeFour.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeFour.dimension_f_5.result/gradeFour.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td>{{pjwdTable.ky_data.filter(item=>{return item.name=='四年级'})[0].value}}%</td>
                                <td>{{(gradeFour.dimension_e_4.result/gradeFour.dimension_e_4.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>由此可见：</p>
                        <p>1）例字相似性角度，作品刻意书写成绩
                            {{(gradeFour.dimension_f_4.result/gradeFour.dimension_f_4.count).toFixed(2)>(gradeFour.dimension_d_4.result/gradeFour.dimension_d_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.like[comparison((gradeFour.dimension_f_4.result/gradeFour.dimension_f_4.count).toFixed(2),(gradeFour.dimension_d_4.result/gradeFour.dimension_d_4.count).toFixed(2))]}}
                        </p>
                        <p>2）占格一致性角度，作品刻意书写成绩
                            {{(gradeFour.dimension_f_5.result/gradeFour.dimension_f_5.count).toFixed(2)>(gradeFour.dimension_e_4.result/gradeFour.dimension_e_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.consistency[comparison((gradeFour.dimension_f_5.result/gradeFour.dimension_f_5.count).toFixed(2),(gradeFour.dimension_e_4.result/gradeFour.dimension_e_4.count).toFixed(2))]}}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.成语补充</div>
                        <img src="../../assets/reportAfter/4-2.png">
                        <p>
                            通过设置语境或补充成语的方式，我们主要考察学生对生字的应用及字义的掌握。通过本题考察，本年级学生的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='四年级'})[0].value}}%，整体还是不错的。
                        </p>

                        <div class="items">2.结构认知</div>
                        <img src="../../assets/reportAfter/4-3.png">
                        <p>通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。经过统计，
                            本题内容的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='四年级'})[0].value}}%。需要注意日常生字间架结构书写特点的理解与记忆。但是结构认知中，也存在一些容易混淆的生字，请着重加强。
                        </p>
                        <div class="items">3.笔顺笔画</div>
                        <img src="../../assets/reportAfter/4-4.png">
                        <p>
                            笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='四年级'})[0].value}}%。
                            学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次后测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{gradeFour.dimension_a_1.result}}人，占{{(gradeFour.dimension_a_1.result*100/gradeFour.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeFour.dimension_a_2.result}}人，占{{(gradeFour.dimension_a_2.result*100/gradeFour.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeFour.dimension_a_3.result}}人，占{{(gradeFour.dimension_a_3.result*100/gradeFour.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeFour.dimension_a_4.result}}人，占{{(gradeFour.dimension_a_4.result*100/gradeFour.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <div class="items">2.掌握汉字含义与应用</div>
                        <p>
                            通过设置补充成语的方式，考察学生对生字的应用及字义的掌握。本次调查，本年级学生对于成语的应用整体较好，本年级学生的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='四年级'})[0].value}}%，请继续保持。
                        </p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>通过设置语境或补充成语的方式，我们主要考察学生对生字的应用及字义的掌握。同时我们考察了不同偏旁部首的书写方式，学生对于生字的组合关系与基本部件的掌握尤为重要。可通过课程学习，系统的积累每一类偏旁部首的书写能力。
                        </p>
                        <div class="items">4.汉字结构理解</div>
                        <p>
                            通过考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分尤为重要，本卷通过以下汉字选择“末稀叉赖疾茅率序”进行考察。经过统计，
                            本题内容的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='四年级'})[0].value}}%。学生还需要进一步掌握对不同结构的字的理解：
                        </p>
                        <p>1）包围结构字形，在日常书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。</p>
                        <p>2）左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上。</p>
                        <p>3）独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。</p>
                        <p>4）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。
                        </p>

                    </div>
                </div>
                <div class="fifth_grade flex-column-center" id="五年级" v-if="JSON.stringify(gradeFive) !== '{}'">
                    <div class="school">{{schoolName}}五年级</div>
                    <div class="name">学生识写能力后测分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、参与情况</div>
                        <p>
                            截止目前，本轮后测中，共计收到来自{{schoolName}}五年级{{gradeFive.dimension_f_6.count}}位同学提交的{{gradeFive.dimension_f_6.result}}页试卷。
                        </p>
                        <table border="1">
                            <tr>
                                <th colspan="3">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>班级</td>
                                <td>参与总人数</td>
                                <td>回收试卷</td>
                            </tr>
                            <tr v-for="(item,index) in tabalFive" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </table>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、整体分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{gradeFive.dimension_a_1.result}}人，占{{(gradeFive.dimension_a_1.result*100/gradeFive.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeFive.dimension_a_2.result}}人，占{{(gradeFive.dimension_a_2.result*100/gradeFive.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeFive.dimension_a_3.result}}人，占{{(gradeFive.dimension_a_3.result*100/gradeFive.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeFive.dimension_a_4.result}}人，占{{(gradeFive.dimension_a_4.result*100/gradeFive.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{gradeFive.dimension_b_1.result}}人，占{{(gradeFive.dimension_b_1.result*100/gradeFive.dimension_b_1.count).toFixed(2)}}%；
                            坐姿2的有{{gradeFive.dimension_b_2.result}}人，占{{(gradeFive.dimension_b_2.result*100/gradeFive.dimension_b_2.count).toFixed(2)}}%；
                            坐姿3的有{{gradeFive.dimension_b_3.result}}人，占{{(gradeFive.dimension_b_3.result*100/gradeFive.dimension_b_3.count).toFixed(2)}}%；
                            坐姿4的有{{gradeFive.dimension_b_4.result}}人，占{{(gradeFive.dimension_b_4.result*100/gradeFive.dimension_b_4.count).toFixed(2)}}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>

                    <div class="part_four">
                        <div class="items">（二）书写基础</div>
                        <p>针对学生写字，我们分为2个场景考察，“日常书写”与“刻意书写”两方面。其中日常书写是要了解学生在日常练习作业等场景快速书写的情况，目的是表现其自然的书写状态；
                            而刻意书写，则是学生集中精力进行规范书写练习，侧重于书写技法考察。</p>
                        <div class="items">1.日常书写</div>
                        <p>日常书写考察，主要通过成语填充、结构组字或归类、笔顺识别写字等场景进行考察，体现在第二、三、四题，我们从例字相似性和占格一致性角度进行分析如下：</p>
                        <table border="1">
                            <tr>
                                <th>题目</th>
                                <th>生字</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                                <th>5~6年级例字相似性全国常模</th>
                                <th>5~6年级占格一致性全国常模</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>愉、榆、瓢、飘</td>
                                <td>{{(gradeFive.dimension_d_1.result/gradeFive.dimension_d_1.count).toFixed(2)}}%</td>
                                <td>{{(gradeFive.dimension_e_1.result/gradeFive.dimension_e_1.count).toFixed(2)}}%</td>
                                <td rowspan="4">85%</td>
                                <td rowspan="4">82%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>锻绷、桑鲁、尾勿、申斤</td>
                                <td>{{(gradeFive.dimension_d_2.result/gradeFive.dimension_d_2.count).toFixed(2)}}%</td>
                                <td>{{(gradeFive.dimension_e_2.result/gradeFive.dimension_e_2.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>插、疑、忌、兼</td>
                                <td>{{(gradeFive.dimension_d_3.result/gradeFive.dimension_d_3.count).toFixed(2)}}%</td>
                                <td>{{(gradeFive.dimension_e_3.result/gradeFive.dimension_e_3.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td colspan="2">平均分值</td>
                                <td>{{(gradeFive.dimension_f_4.result/gradeFive.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeFive.dimension_f_5.result/gradeFive.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <p>从例字相似性角度，五年级学生在日常书写中例字相似性平均水平达到{{(gradeFive.dimension_f_4.result/gradeFive.dimension_f_4.count).toFixed(2)}}%，
                            {{(gradeFive.dimension_f_4.result/gradeFive.dimension_f_4.count).toFixed(2)>85?'高':'低'}}于全国常模85%
                            ；占格一致性平均值{{(gradeFive.dimension_f_5.result/gradeFive.dimension_f_5.count).toFixed(2)}}%，
                            {{(gradeFive.dimension_f_5.result/gradeFive.dimension_f_5.count).toFixed(2)>82?'高':'低'}}于全国常模82%。
                        </p>
                        <div class="items">2.刻意书写</div>
                        <img src="../../assets/reportAfter/5-5.png">
                        <p>通过第五题让学生临写指定的内容，考察学生的“刻意书写”水平。我们对书写内容采用整体测评方式对内容的作品完整度、整体占格一致性、例字相似性、布局整齐度四个维度进行分析。
                            发现学生整体书写水平如下：</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>5~6年级全国常模</th>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>{{(gradeFive.dimension_e_4.result/gradeFive.dimension_e_4.count).toFixed(2)}}%</td>
                                <td>82%</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>{{(gradeFive.dimension_c_4.result*100/gradeFive.dimension_c_4.count).toFixed(2)}}%
                                </td>
                                <td>92%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>{{(gradeFive.dimension_d_4.result/gradeFive.dimension_d_4.count).toFixed(2)}}%</td>
                                <td>85%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>{{(gradeFive.dimension_f_1.result/gradeFive.dimension_f_1.count).toFixed(2)}}%</td>
                                <td>81%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <div class="myChart" id="myChart5"></div>
                        <p>本年级学生中，刻意书写练习作品：</p>
                        <p>
                            1.整体水平为良好作品共{{gradeFive.dimension_f_2.result}}份，占总数的{{(gradeFive.dimension_f_2.result*100/gradeFive.dimension_f_2.count).toFixed(2)}}%，
                            优秀作品{{gradeFive.dimension_f_3.result}}份，占总人数的{{(gradeFive.dimension_f_3.result*100/gradeFive.dimension_f_3.count).toFixed(2)}}%；
                        </p>
                        <p>2.作品平均完整度为{{gradeFiveFull}}%，{{gradeFiveFull>=80?'篇章完整，内容正确。':gradeFiveFull>=60&&gradeFiveFull<80?'篇章较完整。':'篇章不够完整，要正确的书写所有内容。'}}
                        </p>
                        <p>3.卷面布局整齐度为{{gradeFiveLayout}}%，{{gradeFiveLayout>=80?'布局协调，占格居中。':gradeFiveLayout>=60&&gradeFiveLayout<80?'布局基本合理，注意重心位置。':'布局不够整齐，注意上下左右保持对齐。'}}
                        </p>
                        <p>4.占格一致性{{gradeFiveBeautufy}}%
                            ，{{gradeFiveBeautufy>=80?'字的大小适中，书写流畅。':gradeFiveBeautufy>=60&&gradeFiveBeautufy<80?'书写大小基本均匀，内部空间不足。':'字的书写偏差较大，整体偏大/小，主笔位置需找准。'}}
                        </p>
                        <p>5.例字相似性为{{gradeFiveContent}}%，{{gradeFiveContent>=80?'书写工整规范，运笔准确。':gradeFiveContent>=60&&gradeFiveContent<80?'书写基本规范，结构需严谨。':'书写不够规范，部件位置应准确。'}}
                        </p>
                        <div class="items">3.两种书写场景效果对比</div>
                        <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下:</p>
                        <table border="1">
                            <tr>
                                <th>书写场景</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td>{{(gradeFive.dimension_f_4.result/gradeFive.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeFive.dimension_f_5.result/gradeFive.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td>{{pjwdTable.ky_data.filter(item=>{return item.name=='五年级'})[0].value}}%</td>
                                <td>{{(gradeFive.dimension_e_4.result/gradeFive.dimension_e_4.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>由此可见：</p>
                        <p>1）例字相似性角度，作品刻意书写成绩
                            {{(gradeFive.dimension_f_4.result/gradeFive.dimension_f_4.count).toFixed(2)>(gradeFive.dimension_d_4.result/gradeFive.dimension_d_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.like[comparison((gradeFive.dimension_f_4.result/gradeFive.dimension_f_4.count).toFixed(2),(gradeFive.dimension_d_4.result/gradeFive.dimension_d_4.count).toFixed(2))]}}
                        </p>
                        <p>2）占格一致性角度，作品刻意书写成绩
                            {{(gradeFive.dimension_f_5.result/gradeFive.dimension_f_5.count).toFixed(2)>(gradeFive.dimension_e_4.result/gradeFive.dimension_e_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.consistency[comparison((gradeFive.dimension_f_5.result/gradeFive.dimension_f_5.count).toFixed(2),(gradeFive.dimension_e_4.result/gradeFive.dimension_e_4.count).toFixed(2))]}}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.同音字辨析</div>
                        <img src="../../assets/reportAfter/5-2.png">
                        <p>
                            通过选字填空的方式，我们主要考察学生对读音及形似字的辨析与应用。两组生字中，同学生的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='五年级'})[0].value}}%，
                            大多同学能够正确辨析相应生字并完成书写。</p>
                        <div class="items">2.结构认知</div>
                        <img src="../../assets/reportAfter/5-3.png">
                        <p>
                            通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。
                            经过统计，本题内容的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='五年级'})[0].value}}%。需要注意日常生字间架结构书写特点的理解与记忆。但是结构认知中，也存在一些容易混淆的生字，请着重加强。 
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/reportAfter/5-4.png">
                        <p>
                            笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='五年级'})[0].value}}%。
                        </p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次后测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{gradeFive.dimension_a_1.result}}人，占{{(gradeFive.dimension_a_1.result*100/gradeFive.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeFive.dimension_a_2.result}}人，占{{(gradeFive.dimension_a_2.result*100/gradeFive.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeFive.dimension_a_3.result}}人，占{{(gradeFive.dimension_a_3.result*100/gradeFive.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeFive.dimension_a_4.result}}人，占{{(gradeFive.dimension_a_4.result*100/gradeFive.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <div class="items">2.掌握形近字辨析应用</div>
                        <p>通过选字填空的方式，我们主要考察学生对读音及形似字的辨析与应用掌握。本次调查，本年级学生对于相近字的应用整体较好，请继续保持。</p>
                        <div class="items">3.汉字结构理解</div>
                        <p>
                            通过考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分尤为重要，本卷通过以下汉字选择“泰弗聊匙乏魔寞批”进行考察。经过统计，本题内容的平均正确率为{{(gradeFive.dimension_c_2.result*100/gradeFive.dimension_c_2.count).toFixed(2)}}%。
                            学生还需要进一步掌握对不同结构的字的理解：</p>
                        <p>1）包围结构字形，在日常书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。</p>
                        <p>2）左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上。</p>
                        <p>3）独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。</p>
                        <p>4）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。</p>

                    </div>
                </div>
                <div class="sixth_grade flex-column-center" id="六年级" v-if="JSON.stringify(gradeSix) !== '{}'">
                    <div class="school">{{schoolName}}六年级</div>
                    <div class="name">学生识写能力后测分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、参与情况</div>
                        <p>
                            截止目前，本轮后测中，共计收到来自{{schoolName}}六年级{{gradeSix.dimension_f_6.count}}位同学提交的{{gradeSix.dimension_f_6.result}}页试卷。
                        </p>
                        <table border="1">
                            <tr>
                                <th colspan="3">各年级试卷回收情况</th>
                            </tr>
                            <tr>
                                <td>班级</td>
                                <td>参与总人数</td>
                                <td>回收试卷</td>
                            </tr>
                            <tr v-for="(item,index) in tabalSix" :key="index">
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </table>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、整体分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{gradeSix.dimension_a_1.result}}人，占{{(gradeSix.dimension_a_1.result*100/gradeSix.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeSix.dimension_a_2.result}}人，占{{(gradeSix.dimension_a_2.result*100/gradeSix.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeSix.dimension_a_3.result}}人，占{{(gradeSix.dimension_a_3.result*100/gradeSix.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeSix.dimension_a_4.result}}人，占{{(gradeSix.dimension_a_4.result*100/gradeSix.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{gradeSix.dimension_b_1.result}}人，占{{(gradeSix.dimension_b_1.result*100/gradeSix.dimension_b_1.count).toFixed(2)}}%；
                            坐姿2的有{{gradeSix.dimension_b_2.result}}人，占{{(gradeSix.dimension_b_2.result*100/gradeSix.dimension_b_2.count).toFixed(2)}}%；
                            坐姿3的有{{gradeSix.dimension_b_3.result}}人，占{{(gradeSix.dimension_b_3.result*100/gradeSix.dimension_b_3.count).toFixed(2)}}%；
                            坐姿4的有{{gradeSix.dimension_b_4.result}}人，占{{(gradeSix.dimension_b_4.result*100/gradeSix.dimension_b_4.count).toFixed(2)}}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four">
                        <div class="items">（二）书写基础</div>
                        <p>针对学生写字，我们分为2个场景考察，“日常书写”与“刻意书写”两方面。其中日常书写是要了解学生在日常练习作业等场景快速书写的情况，目的是表现其自然的书写状态；
                            而刻意书写，则是学生集中精力进行规范书写练习，侧重于书写技法考察。</p>
                        <div class="items">1.日常书写</div>
                        <p>日常书写考察，主要通过成语填充、结构组字或归类、笔顺识别写字等场景进行考察，体现在第二、三、四题，我们从例字相似性和占格一致性角度进行分析如下：</p>
                        <table border="1">
                            <tr>
                                <th>题目</th>
                                <th>生字</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                                <th>5~6年级例字相似性全国常模</th>
                                <th>5~6年级占格一致性全国常模</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>俱、箭、悔、狂</td>
                                <td>{{(gradeSix.dimension_d_1.result/gradeSix.dimension_d_1.count).toFixed(2)}}%</td>
                                <td>{{(gradeSix.dimension_e_1.result/gradeSix.dimension_e_1.count).toFixed(2)}}%</td>
                                <td rowspan="4">85%</td>
                                <td rowspan="4">82%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>聊批、泰寞、匙魔、弗乏</td>
                                <td>{{(gradeSix.dimension_d_2.result/gradeSix.dimension_d_2.count).toFixed(2)}}%</td>
                                <td>{{(gradeSix.dimension_e_2.result/gradeSix.dimension_e_2.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>藏、御、毙、霉</td>
                                <td>{{(gradeSix.dimension_d_3.result/gradeSix.dimension_d_3.count).toFixed(2)}}%</td>
                                <td>{{(gradeSix.dimension_e_3.result/gradeSix.dimension_e_3.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td colspan="2">平均分值</td>
                                <td>{{(gradeSix.dimension_f_4.result/gradeSix.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeSix.dimension_f_5.result/gradeSix.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <p>从例字相似性角度，六年级学生在日常书写中例字相似性平均水平达到{{(gradeSix.dimension_f_4.result/gradeSix.dimension_f_4.count).toFixed(2)}}%，
                            {{(gradeSix.dimension_f_4.result/gradeSix.dimension_f_4.count).toFixed(2)>85?'高':'低'}}于全国常模85%
                            ；占格一致性平均值{{(gradeSix.dimension_f_5.result/gradeSix.dimension_f_5.count).toFixed(2)}}%，
                            {{(gradeSix.dimension_f_5.result/gradeSix.dimension_f_5.count).toFixed(2)>82?'高':'低'}}于全国常模82%。
                        </p>
                        <div class="items">2.刻意书写</div>
                        <img src="../../assets/reportAfter/6-5.png">
                        <p>通过第五题让学生临写指定的内容，考察学生的“刻意书写”水平。我们对书写内容采用整体测评方式对内容的作品完整度、整体占格一致性、例字相似性、布局整齐度四个维度进行分析。
                            发现学生整体书写水平如下：</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>5~6年级全国常模</th>
                            </tr>
                            <tr>
                                <td>占格一致性</td>
                                <td>{{(gradeSix.dimension_e_4.result/gradeSix.dimension_e_4.count).toFixed(2)}}%</td>
                                <td>82%</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td>{{(gradeSix.dimension_c_4.result*100/gradeSix.dimension_c_4.count).toFixed(2)}}%
                                </td>
                                <td>92%</td>
                            </tr>
                            <tr>
                                <td>例字相似性</td>
                                <td>{{(gradeSix.dimension_d_4.result/gradeSix.dimension_d_4.count).toFixed(2)}}%</td>
                                <td>85%</td>
                            </tr>
                            <tr>
                                <td>布局整齐度</td>
                                <td>{{(gradeSix.dimension_f_1.result/gradeSix.dimension_f_1.count).toFixed(2)}}%</td>
                                <td>81%</td>
                            </tr>
                        </table>
                        <p>针对此，我们发现：</p>
                        <div class="myChart" id="myChart6"></div>
                        <p>本年级学生中，刻意书写练习作品：</p>
                        <p>
                            1.整体水平为良好作品共{{gradeSix.dimension_f_2.result}}份，占总数的{{(gradeSix.dimension_f_2.result*100/gradeSix.dimension_f_2.count).toFixed(2)}}%，
                            优秀作品{{gradeSix.dimension_f_3.result}}份，占总人数的{{(gradeSix.dimension_f_3.result*100/gradeSix.dimension_f_3.count).toFixed(2)}}%；
                        </p>
                        <p>2.作品平均完整度为{{gradeSixFull}}%，{{gradeSixFull>=80?'篇章完整，内容正确。':gradeSixFull>=60&&gradeSixFull<80?'篇章较完整。':'篇章不够完整，要正确的书写所有内容。'}}
                        </p>
                        <p>3.卷面布局整齐度为{{gradeSixLayout}}%，{{gradeSixLayout>=80?'布局协调，占格居中。':gradeSixLayout>=60&&gradeSixLayout<80?'布局基本合理，注意重心位置。':'布局不够整齐，注意上下左右保持对齐。'}}
                        </p>
                        <p>4.占格一致性{{gradeSixBeautufy}}%
                            ，{{gradeSixBeautufy>=80?'字的大小适中，书写流畅。':gradeSixBeautufy>=60&&gradeSixBeautufy<80?'书写大小基本均匀，内部空间不足。':'字的书写偏差较大，整体偏大/小，主笔位置需找准。'}}
                        </p>
                        <p>5.例字相似性为{{gradeSixContent}}%，{{gradeSixContent>=80?'书写工整规范，运笔准确。':gradeSixContent>=60&&gradeSixContent<80?'书写基本规范，结构需严谨。':'书写不够规范，部件位置应准确。'}}
                        </p>
                        <div class="items">3.两种书写场景效果对比</div>
                        <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下:</p>
                        <table border="1">
                            <tr>
                                <th>书写场景</th>
                                <th>例字相似性</th>
                                <th>占格一致性</th>
                            </tr>
                            <tr>
                                <td>日常书写</td>
                                <td>{{(gradeSix.dimension_f_4.result/gradeSix.dimension_f_4.count).toFixed(2)}}%</td>
                                <td>{{(gradeSix.dimension_f_5.result/gradeSix.dimension_f_5.count).toFixed(2)}}%</td>
                            </tr>
                            <tr>
                                <td>刻意书写</td>
                                <td>{{pjwdTable.ky_data.filter(item=>{return item.name=='六年级'})[0].value}}%</td>
                                <td>{{(gradeSix.dimension_e_4.result/gradeSix.dimension_e_4.count).toFixed(2)}}%</td>
                            </tr>
                        </table>
                        <p>由此可见：</p>
                        <p>1）例字相似性角度，作品刻意书写成绩
                            {{(gradeSix.dimension_f_4.result/gradeSix.dimension_f_4.count).toFixed(2)>(gradeSix.dimension_d_4.result/gradeSix.dimension_d_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.like[comparison((gradeSix.dimension_f_4.result/gradeSix.dimension_f_4.count).toFixed(2),(gradeSix.dimension_d_4.result/gradeSix.dimension_d_4.count).toFixed(2))]}}
                        </p>
                        <p>2）占格一致性角度，作品刻意书写成绩
                            {{(gradeSix.dimension_f_5.result/gradeSix.dimension_f_5.count).toFixed(2)>(gradeSix.dimension_e_4.result/gradeSix.dimension_e_4.count).toFixed(2)?'低于':'高于'}}日常书写；
                            {{comments.consistency[comparison((gradeSix.dimension_f_5.result/gradeSix.dimension_f_5.count).toFixed(2),(gradeSix.dimension_e_4.result/gradeSix.dimension_e_4.count).toFixed(2))]}}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="part_title">（三）书写辨析</div>
                        <div class="items">1.成语补充</div>
                        <img src="../../assets/reportAfter/6-2.png">
                        <p>
                            通过设置语境或补充成语的方式，我们主要考察学生对生字的应用及字义的掌握。同学生的平均正确率为{{this.zqlTable.zql_q2.filter(item=>{return item.name=='六年级'})[0].value}}%，大多同学能够正确辨析相应生字并完成书写。
                        </p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/reportAfter/6-3.png">
                        <p>
                            通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。
                            经过统计，本题内容的平均正确率为{{this.zqlTable.zql_q3.filter(item=>{return item.name=='六年级'})[0].value}}%。
                            需要注意日常生字间架结构书写特点的理解与记忆。但是结构认知中，也存在一些容易混淆的生字，请着重加强。 
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/reportAfter/6-4.png">
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{this.zqlTable.zql_q4.filter(item=>{return item.name=='六年级'})[0].value}}%。
                        </p>
                        <p>学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次后测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{gradeSix.dimension_a_1.result}}人，占{{(gradeSix.dimension_a_1.result*100/gradeSix.dimension_a_1.count).toFixed(2)}}%；
                            选择握姿2的有{{gradeSix.dimension_a_2.result}}人，占{{(gradeSix.dimension_a_2.result*100/gradeSix.dimension_a_2.count).toFixed(2)}}%；
                            握姿3的有{{gradeSix.dimension_a_3.result}}人，占{{(gradeSix.dimension_a_3.result*100/gradeSix.dimension_a_3.count).toFixed(2)}}%；
                            握姿4的有{{gradeSix.dimension_a_4.result}}人，占{{(gradeSix.dimension_a_4.result*100/gradeSix.dimension_a_4.count).toFixed(2)}}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span>
                        </div>
                        <div class="items">2.掌握汉字含义与应用</div>
                        <p>通过补充成语的方式，考察学生对生字的应用及字义的掌握。本次调查，本年级学生对于成语的应用整体较好，请继续保持。</p>
                        <div class="items">3.掌握汉字结构</div>
                        <p>
                            通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。
                            经过统计，本题内容的平均正确率为{{(gradeSix.dimension_c_2.result*100/gradeSix.dimension_c_2.count).toFixed(2)}}%。
                            需要注意日常生字间架结构书写特点的理解与记忆。但是结构认知中，也存在一些容易混淆的生字，请着重加强。 
                        </p>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <el-empty style="margin-top:200px" description="暂无数据"></el-empty>
        </template>
    </div>
</template>
<script>
    import {
        getPdf
    } from '../../utils/exportPdf'
    export default {
        data() {
            return {
                isShow: true,
                drawer: true,
                currentType: 'grade',
                currentClass: '',
                currentClassId: '',
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                schoolName: '',
                gradeList: [],
                gradeList2: [],
                allData: {},
                tabalAll: [],
                tabalOne: [],
                tabalTwo: [],
                tabalThree: [],
                tabalFour: [],
                tabalFive: [],
                tabalSix: [],
                gradeOne: {},
                gradeTwo: {},
                gradeThree: {},
                gradeFour: {},
                gradeFive: {},
                gradeSix: {},
                schoolOrgID: '',
                chartOne: [],
                chartTwo: [],
                chartThree: [],
                chartFour: [],
                chartFive: [],
                chartSix: [],
                comments: {
                    consistency: [
                        "学生整体对于单字的书写大小适中，整体表现优异。建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体书写大小基本一致，整体看来稍有不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体字的书写大小偏差较大，分布不均匀，整体看来不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。"
                    ],
                    like: [
                        "学生整体书写工整，与例字相似性较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。",
                        "学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。",
                        "学生整体书写不太规范，与例字相似性较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。"
                    ]
                },

                woziAll: [],
                sitAll: [],
                holdInfo: {
                    allNum: 0,
                    rightNnm: 0,
                    failNum: 0,
                    rightRatio: 0,
                    faliRatio: 0,
                    highName: '',
                    highRatio: 0,
                    lowName: '',
                    lowRatio: 0,
                    hold_1: 0,
                    hold_2: 0,
                    hold_4: 0,
                    ratio_1: 0,
                    ratio_2: 0,
                    ratio_4: 0,
                    mostFail: ''
                },
                sitInfo: {
                    allNum: 0,
                    rightNum: 0,
                    failNum: 0,
                    rightRatio: 0,
                    faliRatio: 0,
                    highName: '',
                    highRatio: 0,
                    lowName: '',
                    lowRatio: 0,
                    hold_1: 0,
                    hold_2: 0,
                    hold_4: 0,
                    ratio_1: 0,
                    ratio_2: 0,
                    ratio_4: 0,
                    mostFail: ''
                },
                allInfo: {
                    stuNum: 0,
                    pageNum: 0,
                    all_wzd: 0,
                    all_xsx: 0,
                    all_yzx: 0,
                    all_zqd: 0,
                    all_baowei: 0,
                    all_shangxia: 0,
                    all_zuoyou: 0,
                    all_duti: 0,
                    all_wzd_a: 0,
                    all_wzd_b: 0,
                    all_wzd_c: 0,
                    all_wzd_high: '',
                    all_xsx_a: 0,
                    all_xsx_b: 0,
                    all_xsx_c: 0,
                    all_xsx_high: '',
                    all_yzx_a: 0,
                    all_yzx_b: 0,
                    all_yzx_c: 0,
                    all_yzx_high: '',
                    all_zqd_a: 0,
                    all_zqd_b: 0,
                    all_zqd_c: 0,
                    all_zqd_high: '',
                    all_wzd_HClass: '',
                    all_wzd_LClass: '',
                    all_xsx_HClass: '',
                    all_xsx_LClass: '',
                    all_yzx_HClass: '',
                    all_yzx_LClass: '',
                    all_zqd_HClass: '',
                    all_zqd_LClass: '',
                },
                allComments: {
                    wzd: [
                        "学生整体书写在内容书写完整度方面表现较为优异，可以较为完整、准确的书写出指定测评文字内容，基本做到不缺字、不漏字。",
                        "学生整体书写在内容书写完整度方面基本达标，可以大部分书写出指定测评文字内容，存在部分错字、涂鸦、缺字、漏字的情况。建议今后的书写中落笔之前多加注意。",
                        "学生整体书写篇章不够完整，整体在内容书写完整度方面有所不足，部分内容未能按照要求书写出指定测评文字内容，错字、涂鸦、缺字、漏字的情况较多。建议今后的书写中减慢速度，先增加书写的准确度。"
                    ],
                    xsx: [
                        "学生整体书写工整，与例字相似性较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。",
                        "学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。",
                        "学生整体书写不太规范，与例字相似性较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。"
                    ],
                    yzx: [
                        "学生整体对于单字的书写大小适中，整体表现优异。建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体书写大小基本一致，整体看来稍有不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体字的书写大小偏差较大，分布不均匀，整体看来不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。"
                    ],
                    zqd: [
                        "学生整体书写布局协调，占格居中。对字组位置间距、篇章行列布局等方面完成较好，书写高低基本一致，没有杂乱感。",
                        "学生整体书写布局基本合理，注意重心位置。对字组位置间距、篇章行列布局等方面仍有提升空间，书写的高低稍有杂乱感，建议可以在今后书写及训练中多加注意。",
                        "学生整体书写布局不够整齐，注意上下左右保持对齐。对字组位置间距、篇章行列布局等方面重视程度不足，致使书写效果高低错落的杂乱感，建议可以在今后的日常书写及训练中加强对行列排布的重视程度。"
                    ]
                },
                constructionInfo: {
                    bwMax: 0,
                    bwMin: 0,
                    bwMaxName: '',
                    bwMinName: '',
                    zyMax: 0,
                    zyMin: 0,
                    zyMaxName: '',
                    zyMinName: '',
                    dtMax: 0,
                    dtMin: 0,
                    dtMaxName: '',
                    dtMinName: '',
                    sxMax: 0,
                    sxMin: 0,
                    sxMaxName: '',
                    sxMinName: '',
                },
                zqlTable: {
                    zql_name: [],
                    zql_q2: [
                        {name:'一年级',value:0},
                        {name:'二年级',value:0},
                        {name:'三年级',value:0},
                        {name:'四年级',value:0},
                        {name:'五年级',value:0},
                        {name:'六年级',value:0},
                    ],
                    zql_q3: [
                        {name:'一年级',value:0},
                        {name:'二年级',value:0},
                        {name:'三年级',value:0},
                        {name:'四年级',value:0},
                        {name:'五年级',value:0},
                        {name:'六年级',value:0},
                    ],
                    zql_q4: [
                        {name:'一年级',value:0},
                        {name:'二年级',value:0},
                        {name:'三年级',value:0},
                        {name:'四年级',value:0},
                        {name:'五年级',value:0},
                        {name:'六年级',value:0},
                    ],
                },
                jgTable: {
                    class: [],
                    zy_data: [],
                    sx_data: [],
                    dt_data: [],
                    bw_data: [],
                },
                pjwdTable: {
                    rc_data: [
                        {name:'一年级',value:0},
                        {name:'二年级',value:0},
                        {name:'三年级',value:0},
                        {name:'四年级',value:0},
                        {name:'五年级',value:0},
                        {name:'六年级',value:0},
                    ],
                    ky_data: [
                        {name:'一年级',value:0},
                        {name:'二年级',value:0},
                        {name:'三年级',value:0},
                        {name:'四年级',value:0},
                        {name:'五年级',value:0},
                        {name:'六年级',value:0},
                    ],
                    compare: '',
                    comment: ''
                },
                gradeList_original:[]
            }
        },
        computed: {
            // 作品完整度
            gradeOneFull() {
                return (this.gradeOne.dimension_c_4.result * 100 / this.gradeOne.dimension_c_4.count).toFixed(2)
            },
            // 布局整齐度
            gradeOneLayout() {
                return (this.gradeOne.dimension_f_1.result / this.gradeOne.dimension_f_1.count).toFixed(2)
            },
            // 占格一致性
            gradeOneBeautufy() {
                return (this.gradeOne.dimension_e_4.result / this.gradeOne.dimension_e_4.count).toFixed(2)
            },
            // 例字相似性
            gradeOneContent() {
                return (this.gradeOne.dimension_d_4.result / this.gradeOne.dimension_d_4.count).toFixed(2)
            },
            gradeTwoFull() {
                return (this.gradeTwo.dimension_c_4.result * 100 / this.gradeTwo.dimension_c_4.count).toFixed(2)
            },
            gradeTwoLayout() {
                return (this.gradeTwo.dimension_f_1.result / this.gradeTwo.dimension_f_1.count).toFixed(2)
            },
            gradeTwoBeautufy() {
                return (this.gradeTwo.dimension_e_4.result / this.gradeTwo.dimension_e_4.count).toFixed(2)
            },
            gradeTwoContent() {
                return (this.gradeTwo.dimension_d_4.result / this.gradeTwo.dimension_d_4.count).toFixed(2)
            },
            gradeThreeFull() {
                return (this.gradeThree.dimension_c_4.result * 100 / this.gradeThree.dimension_c_4.count).toFixed(2)
            },
            gradeThreeLayout() {
                return (this.gradeThree.dimension_f_1.result / this.gradeThree.dimension_f_1.count).toFixed(2)
            },
            gradeThreeBeautufy() {
                return (this.gradeThree.dimension_e_4.result / this.gradeThree.dimension_e_4.count).toFixed(2)
            },
            gradeThreeContent() {
                return (this.gradeThree.dimension_d_4.result / this.gradeThree.dimension_d_4.count).toFixed(2)
            },
            gradeFourFull() {
                return (this.gradeFour.dimension_c_4.result * 100 / this.gradeFour.dimension_c_4.count).toFixed(2)
            },
            gradeFourLayout() {
                return (this.gradeFour.dimension_f_1.result / this.gradeFour.dimension_f_1.count).toFixed(2)
            },
            gradeFourBeautufy() {
                return (this.gradeFour.dimension_e_4.result / this.gradeFour.dimension_e_4.count).toFixed(2)
            },
            gradeFourContent() {
                return (this.gradeFour.dimension_d_4.result / this.gradeFour.dimension_d_4.count).toFixed(2)
            },
            gradeFiveFull() {
                return (this.gradeFive.dimension_c_4.result * 100 / this.gradeFive.dimension_c_4.count).toFixed(2)
            },
            gradeFiveLayout() {
                return (this.gradeFive.dimension_f_1.result / this.gradeFive.dimension_f_1.count).toFixed(2)
            },
            gradeFiveBeautufy() {
                return (this.gradeFive.dimension_e_4.result / this.gradeFive.dimension_e_4.count).toFixed(2)
            },
            gradeFiveContent() {
                return (this.gradeFive.dimension_d_4.result / this.gradeFive.dimension_d_4.count).toFixed(2)
            },
            gradeSixFull() {
                return (this.gradeSix.dimension_c_4.result * 100 / this.gradeSix.dimension_c_4.count).toFixed(2)
            },
            gradeSixLayout() {
                return (this.gradeSix.dimension_f_1.result / this.gradeSix.dimension_f_1.count).toFixed(2)
            },
            gradeSixBeautufy() {
                return (this.gradeSix.dimension_e_4.result / this.gradeSix.dimension_e_4.count).toFixed(2)
            },
            gradeSixContent() {
                return (this.gradeSix.dimension_d_4.result / this.gradeSix.dimension_d_4.count).toFixed(2)
            },
        },
        methods: {
            hideCatalogue() {
                this.isShow = !this.isShow
            },
            exportpdf() {
                getPdf(`${this.schoolName}测评报告`, 'pdfDom')
            },
            changeTpye(type) {
                this.currentType = type;
                if (type == 'grade') {
                    this.data = [];
                    this.gradeList2 = [];
                    this.jgTable = {
                            class: [],
                            zy_data: [],
                            sx_data: [],
                            dt_data: [],
                            bw_data: [],
                        },
                        this.queryUserMaxOrg();
                }
            },
            // 树节点点击事件
            handleNodeClick(data) {
                console.log(data);
                this.currentType = 'person';
                this.currentClass = data.classId ? data.label : data.children[0].label;
                this.currentClassId = data.classId ? data.classId : data.children[0].classId
            },
            // 整体数据
            async initData() {
                let data = {
                    chart: 5,
                    level: 5, // 5学校 6年级 7班级
                    orgId: this.schoolOrgID,
                };
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                // console.log('整体数据', resData);
                this.allData = resData.data["dimension_f_6"];
                this.allInfo.all_wzd = (resData.data.all_wzd.result * 100 / resData.data.all_wzd.count).toFixed(2);
                this.allInfo.all_wzd_a = (resData.data.wzd_a.result * 100 / resData.data.wzd_a.count).toFixed(2);
                this.allInfo.all_wzd_b = (resData.data.wzd_b.result * 100 / resData.data.wzd_b.count).toFixed(2);
                this.allInfo.all_wzd_c = (resData.data.wzd_c.result * 100 / resData.data.wzd_c.count).toFixed(2);
                this.allInfo.all_xsx_a = (resData.data.xsx_a.result * 100 / resData.data.xsx_a.count).toFixed(2);
                this.allInfo.all_xsx_b = (resData.data.xsx_b.result * 100 / resData.data.xsx_b.count).toFixed(2);
                this.allInfo.all_xsx_c = (resData.data.xsx_c.result * 100 / resData.data.xsx_c.count).toFixed(2);
                this.allInfo.all_yzx_a = (resData.data.yzx_a.result * 100 / resData.data.yzx_a.count).toFixed(2);
                this.allInfo.all_yzx_b = (resData.data.yzx_b.result * 100 / resData.data.yzx_b.count).toFixed(2);
                this.allInfo.all_yzx_c = (resData.data.yzx_c.result * 100 / resData.data.yzx_c.count).toFixed(2);
                this.allInfo.all_zqd_a = (resData.data.zqd_a.result * 100 / resData.data.zqd_a.count).toFixed(2);
                this.allInfo.all_zqd_b = (resData.data.zqd_b.result * 100 / resData.data.zqd_b.count).toFixed(2);
                this.allInfo.all_zqd_c = (resData.data.zqd_c.result * 100 / resData.data.zqd_c.count).toFixed(2);
                this.allInfo.all_xsx = (resData.data.all_xsx.result / resData.data.all_xsx.count).toFixed(2);
                this.allInfo.all_yzx = (resData.data.all_yzx.result / resData.data.all_yzx.count).toFixed(2);
                this.allInfo.all_zqd = (resData.data.all_zqd.result / resData.data.all_zqd.count).toFixed(2);
                this.allInfo.all_baowei = (resData.data.xsx_baowei.result / resData.data.xsx_baowei.count).toFixed(
                    2);
                this.allInfo.all_shangxia = (resData.data.xsx_shangxia.result / resData.data.xsx_shangxia.count)
                    .toFixed(2);
                this.allInfo.all_zuoyou = (resData.data.xsx_zuoyou.result / resData.data.xsx_zuoyou.count).toFixed(
                    2);
                this.allInfo.all_duti = (resData.data.xsx_duti.result / resData.data.xsx_duti.count).toFixed(2);

                let xData = [this.allInfo.all_wzd, this.allInfo.all_xsx, this.allInfo
                    .all_yzx, this.allInfo.all_zqd
                ];
                let val = Number(this.allInfo.all_wzd_a) > Number(this.allInfo.all_wzd_b) ? this.allInfo.all_wzd_a :
                    this.allInfo.all_wzd_b;
                let high_wzd = Number(val) > this.allInfo.all_wzd_c ? val : this.allInfo.all_wzd_c;
                this.allInfo.all_wzd_high = high_wzd == this.allInfo.all_wzd_a ? 'A(80-100)' : high_wzd == this
                    .allInfo.all_wzd_b ? 'B(65-80)' : 'C(0-65)';
                let val2 = Number(this.allInfo.all_xsx_a) > Number(this.allInfo.all_xsx_b) ? this.allInfo
                    .all_xsx_a : this.allInfo.all_xsx_b;
                let high_xsx = Number(val2) > this.allInfo.all_xsx_c ? val2 : this.allInfo.all_xsx_c;
                this.allInfo.all_xsx_high = high_xsx == this.allInfo.all_xsx_a ? 'A(80-100)' : high_xsx == this
                    .allInfo.all_xsx_b ? 'B(65-80)' : 'C(0-65)';
                let val3 = Number(this.allInfo.all_yzx_a) > Number(this.allInfo.all_yzx_b) ? this.allInfo
                    .all_yzx_a : this.allInfo.all_yzx_b;
                let high_yzx = Number(val3) > this.allInfo.all_yzx_c ? val3 : this.allInfo.all_yzx_c;
                this.allInfo.all_yzx_high = high_yzx == this.allInfo.all_yzx_a ? 'A(80-100)' : high_yzx == this
                    .allInfo.all_yzx_b ? 'B(65-80)' : 'C(0-65)';
                let val4 = Number(this.allInfo.all_zqd_a) > Number(this.allInfo.all_zqd_b) ? this.allInfo
                    .all_zqd_a : this.allInfo.all_zqd_b;
                let high_zqd = Number(val4) > Number(this.allInfo.all_zqd_c) ? val4 : this.allInfo.all_zqd_c;
                this.allInfo.all_zqd_high = high_zqd == this.allInfo.all_zqd_a ? 'A(80-100)' : high_zqd == this
                    .allInfo.all_zqd_b ? 'B(65-80)' : 'C(0-65)';
                setTimeout(() => {
                    this.initChart15(xData);
                    this.initChart21(xData);
                }, 1000)
            },
            initChart() {
                // console.log('初始化echarts1')
                try {
                    this.$echarts.init(document.getElementById("myChart")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '例字相似性',
                                    max: 100
                                },
                                {
                                    name: '占格一致性',
                                    max: 100
                                },
                                {
                                    name: '布局整齐度',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartOne,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart2() {
                // console.log('初始化echarts2')
                try {
                    this.$echarts.init(document.getElementById("myChart2")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart2"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '例字相似性',
                                    max: 100
                                },
                                {
                                    name: '占格一致性',
                                    max: 100
                                },
                                {
                                    name: '布局整齐度',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartTwo,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart3() {
                console.log('初始化echarts3')
                try {
                    this.$echarts.init(document.getElementById("myChart3")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart3"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '例字相似性',
                                    max: 100
                                },
                                {
                                    name: '占格一致性',
                                    max: 100
                                },
                                {
                                    name: '布局整齐度',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartThree,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart4() {
                console.log('初始化echarts4')
                try {
                    this.$echarts.init(document.getElementById("myChart4")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart4"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '例字相似性',
                                    max: 100
                                },
                                {
                                    name: '占格一致性',
                                    max: 100
                                },
                                {
                                    name: '布局整齐度',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartFour,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart5() {
                console.log('初始化echarts5')
                try {
                    this.$echarts.init(document.getElementById("myChart5")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart5"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '例字相似性',
                                    max: 100
                                },
                                {
                                    name: '占格一致性',
                                    max: 100
                                },
                                {
                                    name: '布局整齐度',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartFive,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart6() {
                console.log('初始化echarts6')
                try {
                    this.$echarts.init(document.getElementById("myChart6")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart6"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '例字相似性',
                                    max: 100
                                },
                                {
                                    name: '占格一致性',
                                    max: 100
                                },
                                {
                                    name: '布局整齐度',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartSix,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart7(hold_right, hold_fail) {
                this.$echarts.init(document.getElementById("myChart7")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart7"));
                myChart.setOption({
                    title: {
                        text: '全校学生握笔姿势分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'center',
                        top: "10%"
                    },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: hold_right,
                                name: '正确握笔姿势',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_right + '%'
                                    },
                                },
                            },
                            {
                                value: hold_fail,
                                name: '错误握笔姿势',
                                itemStyle: {
                                    color: "#F86C6C"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#F86C6C', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_fail + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart8(xdata, data) {
                this.$echarts.init(document.getElementById("myChart8")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart8"));
                myChart.setOption({
                    title: {
                        text: '各年级握姿正确率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        data,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }]
                })
            },
            initChart9(posture_one, posture_two, posture_four) {
                this.$echarts.init(document.getElementById("myChart9")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart9"));
                myChart.setOption({
                    title: {
                        text: '全校学生错误握笔姿势分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    // legend: {
                    //     orient: 'horizontal',
                    //     left: 'center',
                    //     top: "10%"
                    // },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: posture_one,
                                name: '握姿1',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_one + '%'
                                    },
                                },
                            },
                            {
                                value: posture_two,
                                name: '握姿2',
                                itemStyle: {
                                    color: "#FAC858"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#FAC858', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_two + '%'
                                    },
                                },
                            },
                            {
                                value: posture_four,
                                name: '握姿4',
                                itemStyle: {
                                    color: "#5571C9"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#5571C9', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_four + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart10(hold_names, hold_series) {
                this.$echarts.init(document.getElementById("myChart10")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart10"));
                myChart.setOption({
                    title: {
                        text: '各年级学生握笔姿势分布',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'vertical',
                        right: 0, // 将图例放置在 grid 右边
                        top: 'middle',
                        data: hold_names
                    },
                    xAxis: {
                        type: 'category',
                        data: ['握姿1', '握姿2', '握姿4'],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: hold_series
                })
            },
            initChart11(hold_right, hold_fail) {
                this.$echarts.init(document.getElementById("myChart11")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart11"));
                myChart.setOption({
                    title: {
                        text: '全校学生坐姿分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'center',
                        top: "10%"
                    },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: hold_right,
                                name: '正确坐姿',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_right + '%'
                                    },
                                },
                            },
                            {
                                value: hold_fail,
                                name: '错误坐姿',
                                itemStyle: {
                                    color: "#F86C6C"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#F86C6C', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_fail + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart12(xdata, data) {
                this.$echarts.init(document.getElementById("myChart12")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart12"));
                myChart.setOption({
                    title: {
                        text: '各年级坐姿正确率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        data,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }]
                })
            },
            initChart13(posture_one, posture_two, posture_four) {
                this.$echarts.init(document.getElementById("myChart13")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart13"));
                myChart.setOption({
                    title: {
                        text: '全校学生错误坐姿分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    // legend: {
                    //     orient: 'horizontal',
                    //     left: 'center',
                    //     top: "10%"
                    // },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: posture_one,
                                name: '坐姿1',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_one + '%'
                                    },
                                },
                            },
                            {
                                value: posture_two,
                                name: '坐姿2',
                                itemStyle: {
                                    color: "#FAC858"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#FAC858', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_two + '%'
                                    },
                                },
                            },
                            {
                                value: posture_four,
                                name: '坐姿3',
                                itemStyle: {
                                    color: "#5571C9"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#5571C9', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_four + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart14(hold_names, hold_series) {
                this.$echarts.init(document.getElementById("myChart14")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart14"));
                myChart.setOption({
                    title: {
                        text: '各年级学生坐姿分布',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'vertical',
                        right: 0, // 将图例放置在 grid 右边
                        top: 'middle',
                        data: hold_names
                    },
                    xAxis: {
                        type: 'category',
                        data: ['坐姿1', '坐姿2', '坐姿3'],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: hold_series
                })
            },
            initChart15(xdata) {
                this.$echarts.init(document.getElementById("myChart15")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart15"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: ["作品完整度", "例字相似性", "占格一致性", "布局整齐度"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        name: '后测平均水平',
                        data: xdata,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }, {
                        name: '全国常模',
                        data: [82.42, 79.82, 74.45, 73.40],
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        }
                    }],
                    legend: {
                        // data: ['后测平均水平','全国常模'],
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 10
                    },
                })
            },
            initChart16(series) {
                this.$echarts.init(document.getElementById("myChart16")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart16"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: ["左右结构", "上下结构", "独体结构", "包围结构"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: series,
                    legend: {
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 2
                    },
                })
            },
            initChart17(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart17")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart17"));
                myChart.setOption({
                    title: {
                        text: '作品完整度分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart18(wzd_names, wzd_series) {
                // console.log('wzd_series:',wzd_series)
                this.$echarts.init(document.getElementById("myChart18")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart18"));
                myChart.setOption({
                    title: {
                        text: '例字相似性分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart19(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart19")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart19"));
                myChart.setOption({
                    title: {
                        text: '占格一致性分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart20(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart20")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart20"));
                myChart.setOption({
                    title: {
                        text: '布局整齐度分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart21(xdata) {
                this.$echarts.init(document.getElementById("myChart21")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart21"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: ["作品完整度", "例字相似性", "占格一致性", "布局整齐度"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        name: '后测平均水平',
                        data: xdata,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }, {
                        name: '全国常模',
                        data: [82.42, 79.82, 74.45, 73.40],
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        }
                    }],
                    legend: {
                        // data: ['后测平均水平','全国常模'],
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 10
                    },
                })
            },
            initChart22(wzd_names, wzd_series) {
                console.log('wzd_series:',wzd_series)
                this.$echarts.init(document.getElementById("myChart22")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart22"));
                myChart.setOption({
                    title: {
                        text: '各年级书写辨析对比',
                        left: 'center'
                    },
                    grid: {
                        // left: '3%',
                        // right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        bottom: 30
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart23(series) {
                this.$echarts.init(document.getElementById("myChart23")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart23"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: ["左右结构", "上下结构", "独体结构", "包围结构"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: series,
                    legend: {
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 2
                    },
                })
            },
            initChart24(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart24")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart24"));
                myChart.setOption({
                    title: {
                        text: '日常书写与刻意书写对比',
                        left: 'center'
                    },
                    grid: {
                        // left: '3%',
                        // right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        bottom: 30
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },

            // 整体报告表格
            async initTableData() {
                let data = {
                    chart: 5,
                    level: 5,
                    orgId: this.schoolOrgID,
                    attId: 26
                }
                let resData = await this.$Api.DataScreen.queryTableData(data);
                // console.log('整体报告数据:', resData);
                let total = {
                    name: '合计',
                    allPage: 0,
                    count: 0,
                    result: 0,
                    failPage: 0
                }
                resData.data.map(item => {
                    item.allPage = item.count * 2 > item.result ? item.count * 2 : item.result;
                    item.failPage = item.allPage - item.result;
                    total.allPage += item.allPage;
                    total.count += item.count;
                    total.result += item.result;
                    total.failPage += item.failPage
                })
                // console.log(total);
                resData.data.push(total);
                this.tabalAll = resData.data;
                this.allInfo.stuNum = total.count;
                this.allInfo.pageNum = total.allPage;
            },
            //判断用户角色组织
            async queryUserMaxOrg() {
                let resData = await this.$Api.Home.queryUserMaxOrg();
                // console.log("resData", resData);
                this.schoolOrgID = resData.data.orgId;
                this.schoolName = resData.data.name
                this.queryOrgInfo();
                this.initData();
                this.initTableData();
                setTimeout(() => {
                    this.initChart();
                    this.initChart2();
                    this.initChart3();
                    this.initChart4();
                    this.initChart5();
                    this.initChart6();
                }, 1000)
            },
            // 获取年级列表
            async queryOrgInfo() {
                let data = {
                    orgId: this.schoolOrgID,
                };
                let resData = await this.$Api.DataScreen.queryOrgInfo(data);
                // console.log('年级列表:', resData)
                this.gradeList = resData.data.sonOrgList;
                this.gradeList_original = resData.data.sonOrgList;
                let arr = [];
                let series = [];
                let wzd_names = []; //各年级作品完整度x轴
                let wzd_series = []; //各年级作品完整度series
                let xsx_series = []; //各年级例字相似性series
                let yzx_series = []; //各年级占格一致性series
                let zqd_series = []; //各年级布局整齐度series
                let zql_series = []; //各年级234题正确率series
                let sx_series = []; //日常书写与刻意书写series
                let wzd_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let wzd_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let wzd_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let xsx_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let xsx_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let xsx_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let yzx_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let yzx_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let yzx_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zqd_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zqd_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zqd_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zql_q2 = {
                    data: [],
                    type: 'bar',
                    name: '生字音形义辨析',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zql_q3 = {
                    data: [],
                    type: 'bar',
                    name: '不同结构生字辨析',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zql_q4 = {
                    data: [],
                    type: 'bar',
                    name: '不同笔顺笔画生字辨析',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let rcsx = {
                    data: [],
                    type: 'bar',
                    name: '日常书写',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let kysx = {
                    data: [],
                    type: 'bar',
                    name: '刻意书写',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };

                // 测试Promise.all()
                const promises = this.gradeList.map(item => {
                    let data = {
                        chart: 5,
                        level: 6, // 5学校 6年级 7班级
                        orgId: item.orgId,
                    }
                    return this.$Api.DataScreen.basicsStatistics(data)
                        .then(res => {
                            res.name = item.name;
                            return res
                        })
                })
                Promise.all(promises)
                    .then(res => {
                        console.log('Promise.all:', res);
                        let woziAll = [];
                        let sitAll = [];
                        let total_hold = {
                            name: '合计',
                            posture_one: 0,
                            posture_two: 0,
                            posture_three: 0,
                            posture_four: 0
                        }
                        let total_sit = {
                            name: '合计',
                            sit_one: 0,
                            sit_two: 0,
                            sit_three: 0,
                            sit_four: 0,
                        }
                        let hold_xdata = [];
                        let hold_data = [];
                        let hold_series = [];
                        let hold_names = [];
                        let sit_xdata = [];
                        let sit_data = [];
                        let sit_series = [];
                        let sit_names = [];
                        res.map(item => {
                            if (JSON.stringify(item.data) !== '{}') {
                                arr.push(item.name);
                                if (item.name == '一年级') {
                                    this.gradeOne = item.data;
                                    this.chartOne = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ];
                                    let wzObj = {
                                        name: '1年级',
                                        posture_one: this.gradeOne.dimension_a_1.result,
                                        posture_two: this.gradeOne.dimension_a_2.result,
                                        posture_three: this.gradeOne.dimension_a_3.result,
                                        posture_four: this.gradeOne.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '1年级',
                                        sit_one: this.gradeOne.dimension_b_1.result,
                                        sit_two: this.gradeOne.dimension_b_2.result,
                                        sit_three: this.gradeOne.dimension_b_3.result,
                                        sit_four: this.gradeOne.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);

                                } else if (item.name == '二年级') {
                                    this.gradeTwo = item.data;
                                    this.chartTwo = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ];
                                    let wzObj = {
                                        name: '2年级',
                                        posture_one: this.gradeTwo.dimension_a_1.result,
                                        posture_two: this.gradeTwo.dimension_a_2.result,
                                        posture_three: this.gradeTwo.dimension_a_3.result,
                                        posture_four: this.gradeTwo.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '2年级',
                                        sit_one: this.gradeTwo.dimension_b_1.result,
                                        sit_two: this.gradeTwo.dimension_b_2.result,
                                        sit_three: this.gradeTwo.dimension_b_3.result,
                                        sit_four: this.gradeTwo.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                } else if (item.name == '三年级') {
                                    this.gradeThree = item.data;
                                    this.chartThree = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ];
                                    let wzObj = {
                                        name: '3年级',
                                        posture_one: this.gradeThree.dimension_a_1.result,
                                        posture_two: this.gradeThree.dimension_a_2.result,
                                        posture_three: this.gradeThree.dimension_a_3.result,
                                        posture_four: this.gradeThree.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '3年级',
                                        sit_one: this.gradeThree.dimension_b_1.result,
                                        sit_two: this.gradeThree.dimension_b_2.result,
                                        sit_three: this.gradeThree.dimension_b_3.result,
                                        sit_four: this.gradeThree.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                } else if (item.name == '四年级') {
                                    this.gradeFour = item.data;
                                    this.chartFour = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ];
                                    let wzObj = {
                                        name: '4年级',
                                        posture_one: this.gradeFour.dimension_a_1.result,
                                        posture_two: this.gradeFour.dimension_a_2.result,
                                        posture_three: this.gradeFour.dimension_a_3.result,
                                        posture_four: this.gradeFour.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '4年级',
                                        sit_one: this.gradeFour.dimension_b_1.result,
                                        sit_two: this.gradeFour.dimension_b_2.result,
                                        sit_three: this.gradeFour.dimension_b_3.result,
                                        sit_four: this.gradeFour.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                } else if (item.name == '五年级') {
                                    this.gradeFive = item.data;
                                    this.chartFive = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ];
                                    let wzObj = {
                                        name: '5年级',
                                        posture_one: this.gradeFive.dimension_a_1.result,
                                        posture_two: this.gradeFive.dimension_a_2.result,
                                        posture_three: this.gradeFive.dimension_a_3.result,
                                        posture_four: this.gradeFive.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '5年级',
                                        sit_one: this.gradeFive.dimension_b_1.result,
                                        sit_two: this.gradeFive.dimension_b_2.result,
                                        sit_three: this.gradeFive.dimension_b_3.result,
                                        sit_four: this.gradeFive.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                } else if (item.name == '六年级') {
                                    this.gradeSix = item.data;
                                    this.chartSix = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ];
                                    let wzObj = {
                                        name: '6年级',
                                        posture_one: this.gradeSix.dimension_a_1.result,
                                        posture_two: this.gradeSix.dimension_a_2.result,
                                        posture_three: this.gradeSix.dimension_a_3.result,
                                        posture_four: this.gradeSix.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '6年级',
                                        sit_one: this.gradeSix.dimension_b_1.result,
                                        sit_two: this.gradeSix.dimension_b_2.result,
                                        sit_three: this.gradeSix.dimension_b_3.result,
                                        sit_four: this.gradeSix.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                }





                                let obj = {
                                    name: item.name,
                                    data: [],
                                    type: 'line',
                                    smooth: true
                                }
                                let zuoyou = (item.data.xsx_zuoyou.result / item.data.xsx_zuoyou.count)
                                    .toFixed(2);
                                let shangxia = (item.data.xsx_shangxia.result / item.data.xsx_shangxia
                                    .count).toFixed(2);
                                let duti = (item.data.xsx_duti.result / item.data.xsx_duti.count)
                                    .toFixed(2);
                                let baowei = (item.data.xsx_baowei.result / item.data.xsx_baowei.count)
                                    .toFixed(2);
                                obj.data.push(zuoyou, shangxia, duti, baowei);
                                this.jgTable.zy_data.push(zuoyou);
                                this.jgTable.sx_data.push(shangxia);
                                this.jgTable.dt_data.push(duti);
                                this.jgTable.bw_data.push(baowei);
                                series.push(obj);
                                // 各年级作品完整度柱状图
                                wzd_names.push(item.name);
                                wzd_a.data.push((item.data.wzd_a.result * 100 / item.data.wzd_a.count)
                                    .toFixed(2));
                                wzd_b.data.push((item.data.wzd_b.result * 100 / item.data.wzd_b.count)
                                    .toFixed(2));
                                wzd_c.data.push((item.data.wzd_c.result * 100 / item.data.wzd_c.count)
                                    .toFixed(2));
                                xsx_a.data.push((item.data.xsx_a.result * 100 / item.data.xsx_a.count)
                                    .toFixed(2));
                                xsx_b.data.push((item.data.xsx_b.result * 100 / item.data.xsx_b.count)
                                    .toFixed(2));
                                xsx_c.data.push((item.data.xsx_c.result * 100 / item.data.xsx_c.count)
                                    .toFixed(2));
                                yzx_a.data.push((item.data.yzx_a.result * 100 / item.data.yzx_a.count)
                                    .toFixed(2));
                                yzx_b.data.push((item.data.yzx_b.result * 100 / item.data.yzx_b.count)
                                    .toFixed(2));
                                yzx_c.data.push((item.data.yzx_c.result * 100 / item.data.yzx_c.count)
                                    .toFixed(2));
                                zqd_a.data.push((item.data.zqd_a.result * 100 / item.data.zqd_a.count)
                                    .toFixed(2));
                                zqd_b.data.push((item.data.zqd_b.result * 100 / item.data.zqd_b.count)
                                    .toFixed(2));
                                zqd_c.data.push((item.data.zqd_c.result * 100 / item.data.zqd_c.count)
                                    .toFixed(2));
                                zql_q2.data.push({
                                    value:(item.data.q2_zql.result * 100 / item.data.q2_zql.count).toFixed(2),
                                    name:item.name
                                })
                                zql_q3.data.push({
                                    value:(item.data.q3_zql.result * 100 / item.data.q3_zql
                                    .count).toFixed(2),
                                    name:item.name
                                })
                                zql_q4.data.push({
                                    value:(item.data.q4_zql.result * 100 / item.data.q4_zql
                                    .count).toFixed(2),
                                    name:item.name
                                })
                                rcsx.data.push({
                                    value: (item.data.dimension_f_4.result / item.data
                                        .dimension_f_4.count).toFixed(2),
                                    name: item.name
                                })
                                kysx.data.push({
                                    value: (item.data.dimension_d_4.result / item.data
                                        .dimension_d_4.count).toFixed(2),
                                    name: item.name
                                })
                            } else {
                                 let obj = {
                                    name: item.name,
                                    data: [],
                                    type: 'line',
                                    smooth: true
                                }
                                let zuoyou = 0;
                                let shangxia = 0;
                                let duti = 0;
                                let baowei = 0;
                                obj.data.push(zuoyou, shangxia, duti, baowei);
                                this.jgTable.zy_data.push(zuoyou);
                                this.jgTable.sx_data.push(shangxia);
                                this.jgTable.dt_data.push(duti);
                                this.jgTable.bw_data.push(baowei);
                                series.push(obj);
                                // console.log('当前年级没有数据:', item.name)
                                wzd_names.push(item.name);
                                wzd_a.data.push(0);
                                wzd_b.data.push(0);
                                wzd_c.data.push(0);
                                xsx_a.data.push(0);
                                xsx_b.data.push(0);
                                xsx_c.data.push(0);
                                yzx_a.data.push(0);
                                yzx_b.data.push(0);
                                yzx_c.data.push(0);
                                zqd_a.data.push(0);
                                zqd_b.data.push(0);
                                zqd_c.data.push(0);
                                 zql_q2.data.push({
                                    value:0,
                                    name:item.name
                                })
                                zql_q3.data.push({
                                    value:0,
                                    name:item.name
                                })
                                zql_q4.data.push({
                                    value:0,
                                    name:item.name
                                })
                                rcsx.data.push({
                                    value: 0,
                                    name: item.name
                                })
                                kysx.data.push({
                                    value: 0,
                                    name: item.name
                                })
                            }
                        })

                        let arr2 = [];
                        this.gradeList.map(item => {
                            arr.map(items => {
                                if (item.name == items) {
                                    arr2.push(item)
                                }
                            })
                        })
                        console.log('arr2:', arr2)
                        this.gradeList = arr2;
                        // 握姿series
                        woziAll.map(item => {
                            total_hold.posture_one += item.posture_one,
                                total_hold.posture_two += item.posture_two,
                                total_hold.posture_three += item
                                .posture_three,
                                total_hold.posture_four += item.posture_four,
                                item.total = item.posture_four + item.posture_one + item
                                .posture_two + item.posture_three,
                                item.rightRatio = (item.posture_three / item.total *
                                    100)
                                .toFixed(2),
                                hold_xdata.push(item.name);
                            hold_data.push(item.rightRatio);
                            // 计算各年级错误握姿统计
                            let obj = {
                                name: item.name,
                                data: [item.posture_one, item.posture_two, item
                                    .posture_four
                                ],
                                type: 'bar',
                                label: {
                                    show: true,
                                    position: 'top'
                                },
                                barWidth: 20
                            }
                            hold_series.push(obj);
                            hold_names.push(item.name);
                        })
                        sitAll.map(item => {
                            total_sit.sit_one += item.sit_one,
                                total_sit.sit_two += item.sit_two,
                                total_sit.sit_three += item.sit_three,
                                total_sit.sit_four += item.sit_four,
                                item.total = item.sit_four + item.sit_one + item
                                .sit_two +
                                item.sit_three,
                                item.rightRatio = (item.sit_four / item.total * 100)
                                .toFixed(2),
                                sit_xdata.push(item.name);
                            sit_data.push(item.rightRatio);
                            let obj = {
                                name: item.name,
                                data: [item.sit_one, item.sit_two, item.sit_three],
                                type: 'bar',
                                label: {
                                    show: true,
                                    position: 'top'
                                },
                                barWidth: 20
                            }
                            sit_series.push(obj);
                            sit_names.push(item.name);
                        })
                        // 计算全校握姿
                        let hold_total = total_hold.posture_one + total_hold
                            .posture_two + total_hold.posture_three + total_hold
                            .posture_four;
                        let hold_right = (total_hold.posture_three / hold_total * 100).toFixed(
                            2);
                        let hold_fail = 100 - hold_right;
                        this.holdInfo.allNum = hold_total;
                        this.holdInfo.rightNnm = total_hold.posture_three;
                        this.holdInfo.failNum = hold_total - total_hold.posture_three;
                        this.holdInfo.rightRatio = hold_right;
                        this.holdInfo.faliRatio = hold_fail.toFixed(2);
                        this.initChart7(hold_right, hold_fail.toFixed(2));
                        // 计算全校坐姿
                        let sit_total = total_sit.sit_one + total_sit.sit_two + total_sit
                            .sit_three + total_sit.sit_four;
                        let sit_right = (total_sit.sit_four / sit_total * 100).toFixed(2);
                        let sit_fail = (100 - sit_right).toFixed(2);
                        this.sitInfo.allNum = sit_total;
                        this.sitInfo.rightNum = total_sit.sit_four;
                        this.sitInfo.failNum = sit_total - total_sit.sit_four;
                        this.sitInfo.rightRatio = sit_right;
                        this.sitInfo.faliRatio = sit_fail;
                        this.initChart11(sit_right, sit_fail);
                        // 计算各年级学生握笔姿势正确率
                        let minValue = hold_data[0];
                        let minIndex = 0;
                        let maxValue = hold_data[0];
                        let maxIndex = 0;
                        for (let i = 1; i < hold_data.length; i++) {
                            if (Number(hold_data[i]) < minValue) {
                                minValue = Number(hold_data[i]);
                                minIndex = i;
                            } else if (Number(hold_data[i]) > maxValue) {
                                maxValue = Number(hold_data[i]);
                                maxIndex = i;
                            }
                        }
                        let sitMinValue = sit_data[0];
                        let sitMinIndex = 0;
                        let sitMaxValue = sit_data[0];
                        let sitMaxIndex = 0;
                        for (let i = 1; i < sit_data.length; i++) {
                            if (Number(sit_data[i]) < sitMinValue) {
                                sitMinValue = Number(sit_data[i]);
                                sitMinIndex = i;
                            } else if (Number(sit_data[i]) > sitMaxValue) {
                                sitMaxValue = Number(sit_data[i]);
                                sitMaxIndex = i;
                            }
                        }

                        this.holdInfo.highName = hold_xdata[maxIndex];
                        this.holdInfo.highRatio = maxValue;
                        this.holdInfo.lowName = hold_xdata[minIndex];
                        this.holdInfo.lowRatio = minValue;
                        this.sitInfo.highName = sit_xdata[sitMaxIndex];
                        this.sitInfo.highRatio = sitMaxValue;
                        this.sitInfo.lowName = sit_xdata[sitMinIndex];
                        this.sitInfo.lowRatio = sitMinValue;
                        this.initChart8(hold_xdata, hold_data);
                        this.initChart12(sit_xdata, sit_data);

                        // 计算全校学生错误握笔姿势分布
                        this.holdInfo.hold_1 = total_hold.posture_one;
                        this.holdInfo.hold_2 = total_hold.posture_two;
                        this.holdInfo.hold_4 = total_hold.posture_four;
                        this.holdInfo.ratio_1 = (total_hold.posture_one / this.holdInfo
                            .failNum *
                            100).toFixed(2);
                        this.holdInfo.ratio_2 = (total_hold.posture_two / this.holdInfo
                            .failNum *
                            100).toFixed(2);
                        this.holdInfo.ratio_4 = (total_hold.posture_four / this.holdInfo
                            .failNum *
                            100).toFixed(2);
                        this.sitInfo.hold_1 = total_sit.sit_one;
                        this.sitInfo.hold_2 = total_sit.sit_two;
                        this.sitInfo.hold_4 = total_sit.sit_three;
                        this.sitInfo.ratio_1 = (total_sit.sit_one / this.sitInfo.failNum * 100)
                            .toFixed(2);
                        this.sitInfo.ratio_2 = (total_sit.sit_two / this.sitInfo.failNum * 100)
                            .toFixed(2);
                        this.sitInfo.ratio_4 = (total_sit.sit_three / this.sitInfo.failNum *
                                100)
                            .toFixed(2);
                        this.initChart9(this.holdInfo.ratio_1, this.holdInfo.ratio_2, this
                            .holdInfo
                            .ratio_4);
                        this.initChart13(this.sitInfo.ratio_1, this.sitInfo.ratio_2, this
                            .sitInfo
                            .ratio_4);
                        // console.log('柱状图name:', sit_names)
                        // console.log('柱状图data:', sit_series);
                        let max = Object.keys(total_hold)
                            .filter(key => key.startsWith("posture"))
                            .reduce((a, b) => a > b ? a : b);
                        let mostObj = {
                            posture_one: 1,
                            posture_two: 2,
                            posture_four: 4
                        }
                        let sitMostObj = {
                            '坐姿1': this.sitInfo.hold_1,
                            '坐姿2': this.sitInfo.hold_2,
                            '坐姿3': this.sitInfo.hold_4,
                        }
                        let sitMax = Object.keys(sitMostObj).reduce((a, b) => a > b ? a : b);
                        // console.log('sitMax:', sitMax)
                        this.holdInfo.mostFail = mostObj[max];
                        this.sitInfo.mostFail = sitMax;
                        this.initChart10(hold_names, hold_series)
                        this.initChart14(sit_names, sit_series)
                        woziAll.push(total_hold);
                        sitAll.push(total_sit);
                        this.woziAll = woziAll;
                        this.sitAll = sitAll;
                        console.log('正确握姿数量:', hold_total)






                        wzd_series.push(wzd_a, wzd_b, wzd_c);
                        xsx_series.push(xsx_a, xsx_b, xsx_c);
                        yzx_series.push(yzx_a, yzx_b, yzx_c);
                        zqd_series.push(zqd_a, zqd_b, zqd_c);
                        zql_series.push(zql_q2, zql_q3, zql_q4);
                        sx_series.push(rcsx, kysx)
                    })
                    .then(res => {
                        console.log('wzd_series:', wzd_series);
                        this.jgTable.class = wzd_names;
                        this.pjwdTable.rc_data = sx_series[0].data;
                        this.pjwdTable.ky_data = sx_series[1].data;
                        var rc_count = 0;
                        let ky_count = 0;
                        sx_series[0].data.map(item => {
                            rc_count += Number(item)
                        })
                        sx_series[1].data.map(item => {
                            ky_count += Number(item)
                        })

                        let rc_mean = (rc_count / wzd_names.length).toFixed(2);
                        let ky_mean = (ky_count / wzd_names.length).toFixed(2);
                        let minVal = rc_mean > ky_mean ? ky_mean : rc_mean;
                        // console.log('rc_mean:', rc_mean);
                        // console.log('ky_mean:', ky_mean);
                        // console.log('minVal:', minVal);
                        if (rc_mean > ky_mean) {
                            this.pjwdTable.compare = '稍低'
                        } else {
                            this.pjwdTable.compare = '稍高'
                        }
                        if (minVal >= 80) {
                            this.pjwdTable.comment =
                                '学生整体书写工整，与例字相似性较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。'
                        } else if (minVal >= 65 && minVal < 80) {
                            this.pjwdTable.comment =
                                '学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。'
                        } else if (minVal < 65) {
                            this.pjwdTable.comment =
                                '学生整体书写不太规范，与例字相似性较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。'
                        }

                        this.zqlTable.zql_name = wzd_names;
                        this.zqlTable.zql_q2 = zql_series[0].data;
                        this.zqlTable.zql_q3 = zql_series[1].data;
                        this.zqlTable.zql_q4 = zql_series[2].data;

                        let wzdData = wzd_series.filter(item => {
                            return item.name == this.allInfo.all_wzd_high
                        })[0].data;
                        let xsxData = xsx_series.filter(item => {
                            return item.name == this.allInfo.all_xsx_high
                        })[0].data;
                        let yzxData = yzx_series.filter(item => {
                            return item.name == this.allInfo.all_yzx_high
                        })[0].data;
                        let zqdData = zqd_series.filter(item => {
                            return item.name == this.allInfo.all_zqd_high
                        })[0].data;
                        this.comparisonClass(wzdData, 'wzd');
                        this.comparisonClass(xsxData, 'xsx');
                        this.comparisonClass(yzxData, 'yzx');
                        this.comparisonClass(zqdData, 'zqd');
                        this.initChart16(series);
                        this.initChart23(series);
                        this.initChart17(wzd_names, wzd_series);
                        this.initChart18(wzd_names, xsx_series);
                        this.initChart19(wzd_names, yzx_series);
                        this.initChart20(wzd_names, zqd_series);
                        this.initChart22(wzd_names, zql_series);
                        this.initChart24(wzd_names, sx_series);
                        // 各年级4项结构对比
                        let baowei = [];
                        let zuoyou = [];
                        let duti = [];
                        let shangxia = [];
                        series.map(item => {
                            let zyobj = {
                                name: item.name,
                                value: Number(item.data[0])
                            }
                            let sxobj = {
                                name: item.name,
                                value: Number(item.data[1])
                            }
                            let dtobj = {
                                name: item.name,
                                value: Number(item.data[2])
                            }
                            let bwobj = {
                                name: item.name,
                                value: Number(item.data[3])
                            }

                            baowei.push(bwobj);
                            zuoyou.push(zyobj);
                            duti.push(dtobj);
                            shangxia.push(sxobj)
                        })
                        const bwResult = baowei.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        const zyResult = zuoyou.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        const dtResult = duti.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        const sxResult = shangxia.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        this.constructionInfo = {
                            bwMax: bwResult.max,
                            bwMin: bwResult.min,
                            bwMaxName: bwResult.maxName,
                            bwMinName: bwResult.minName,
                            zyMax: zyResult.max,
                            zyMin: zyResult.min,
                            zyMaxName: zyResult.maxName,
                            zyMinName: zyResult.minName,
                            dtMax: dtResult.max,
                            dtMin: dtResult.min,
                            dtMaxName: dtResult.maxName,
                            dtMinName: dtResult.minName,
                            sxMax: sxResult.max,
                            sxMin: sxResult.min,
                            sxMaxName: sxResult.maxName,
                            sxMinName: sxResult.minName,
                        }

                    })
                    .catch(err => {
                        console.log('Promise.all失败:', err)
                    })
                const promise2 = this.gradeList.map(item => {
                    let data2 = {
                        chart: 5,
                        level: 6,
                        orgId: item.orgId,
                        attId: 26
                    }
                    return this.$Api.DataScreen.queryTableData(data2)
                        .then(resData => {
                            resData.name = item.name;
                            return resData;
                        })
                        .catch(err => {
                            console.log('err:', err)
                        })
                })
                Promise.all(promise2)
                    .then(resData => {
                        // console.log('Promise.all2:', resData);
                        // console.log('个人报告数据:', resData)
                        resData.map(item => {
                            if (item.name == '一年级') {
                                this.tabalOne = item.data;
                            } else if (item.name == '二年级') {
                                this.tabalTwo = item.data;
                            } else if (item.name == '三年级') {
                                this.tabalThree = item.data;

                            } else if (item.name == '四年级') {
                                this.tabalFour = item.data;

                            } else if (item.name == '五年级') {
                                this.tabalFive = item.data;

                            } else if (item.name == '六年级') {
                                this.tabalSix = item.data;
                            }
                        })

                    })
            },
            // 对比一致性与相似性
            comparison(val1, val2) {
                let result;
                if (val1 < val2) {
                    result = val1
                } else {
                    result = val2
                }
                // console.log('result:', result);
                if (result >= 80) {
                    return 0
                } else if (result >= 65 && result < 80) {
                    return 1
                } else {
                    return 2
                }
            },
            // 书写维度评语判断
            comparisonWd(val) {
                if (val >= 80) {
                    return 0
                }
                if (val >= 60 && val < 80) {
                    return 1
                }
                if (val < 60) {
                    return 2
                }
            },
            // 书写基础计算各柱状图最高最低年级
            comparisonClass(arr, type) {
                console.log('arr:',arr,'type:',type)
                let max = Number(arr[0]);
                let maxIndex = 0;
                let min = Number(arr[0]);
                let minIndex = 0;
                let classObj = {
                    0: '一年级',
                    1: '二年级',
                    2: '三年级',
                    3: '四年级',
                    4: '五年级',
                    5: '六年级'
                }

                for (let i = 1; i < arr.length; i++) {
                    if (Number(arr[i]) > max) {
                        maxIndex = i;
                        max = Number(arr[i]);
                    }

                    if (Number(arr[i]) < min) {
                        minIndex = i;
                        min = Number(arr[i]);
                    }
                }
                switch (type) {
                    case 'wzd':
                        this.allInfo.all_wzd_HClass = classObj[maxIndex];
                        this.allInfo.all_wzd_LClass = classObj[minIndex];
                        break;
                    case 'xsx':
                        this.allInfo.all_xsx_HClass = classObj[maxIndex];
                        this.allInfo.all_xsx_LClass = classObj[minIndex];
                        break;
                    case 'yzx':
                        this.allInfo.all_yzx_HClass = classObj[maxIndex];
                        this.allInfo.all_yzx_LClass = classObj[minIndex];
                        break;
                    case 'zqd':
                        this.allInfo.all_zqd_HClass = classObj[maxIndex];
                        this.allInfo.all_zqd_LClass = classObj[minIndex];
                        break;
                }
            },
            // 计算2、3、4题最低正确率
            computedMinVal(data){
                let minValue = data[0].value;
                let minIndex = 0;
                for(let i=1; i<data.length; i++){
                if(data[i].value < minValue){
                    minValue = data[i].value;
                    minIndex = i;
                    }
                }
                let obj = {
                    name:data[minIndex].name,
                    value:minValue
                }
                return obj
            }
        },
        mounted() {
            this.queryUserMaxOrg();
        },
    };
</script>
<style lang='less' scoped>
    @import url("./less/report.less");
</style>